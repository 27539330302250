export class Mouse {
  public x: number;
  public y: number;

  constructor() {
    this.x = 0;
    this.y = 0;
  }

  getPosition(event, canvas) {
    const scrollLeft = document.querySelector(".col-canvas")?.scrollLeft ?? 0;
    let mx = 0,
      my = 0,
      canvasOpt = canvas;
    if (canvasOpt) {
      if (canvasOpt.offsetParent !== undefined) {
        do {
          mx += canvasOpt.offsetLeft;
          my += canvasOpt.offsetTop;
        } while ((canvasOpt = canvasOpt.offsetParent));
      }
      this.x =
        (event.touches && event.touches.length
          ? event.touches[0].pageX
          : event.pageX) -
        mx +
        scrollLeft;
      this.y =
        (event.touches && event.touches.length
          ? event.touches[0].pageY
          : event.pageY) - my;
      return this;
    }
  }
}
