import $ from "jquery";
import { LIMIT_SHAPES, Stage } from "./Stage";
import { useState, useEffect, useCallback } from "react";
import { Shape } from "./Shape";
import _ from "lodash";
import { usePlayScriptShapes } from "./hooks/usePlayScriptShapes";
import { usePlayScriptTerminarShapes } from "./hooks/usePlayScriptTerminarShapes";
import { message } from "antd";
import { updateSizeCanvasDesign } from "../../create-design";

const copyToClipboard = (data) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(data);
  } else {
    const input = document.createElement("textarea");
    input.value = data;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  }
};

const pasteToClipBoard = () => {
  return new Promise((resolve) => {
    if (navigator.clipboard) {
      navigator.clipboard.readText().then(
        (cliptext) => resolve(cliptext),
        (err) => console.log(err)
      );
    }
  });
};

const createClonedShape = (shape, id?: number) => {
  const shapeSelected = _.cloneDeep(shape);
  if (shapeSelected) {
    let shape = new Shape({
      ...shapeSelected,
      x: shapeSelected.x + 10,
      y: shapeSelected.y + 10,
      matchId: [],
      id,
      base64Image: shapeSelected.image64,
      image64: "",
    });
    return shape;
  }
};

export const useGameState = ({ selectedGameName }: any) => {
  const [pauseSceneState, setPauseSceneState] = useState(false);
  const [image, setImage] = useState("");
  const [name, setName] = useState(selectedGameName);
  const [events, setEvents] = useState<any>(false);
  const [codigo, setCodigo] = useState<number>();
  const [endGame, setEndGame] = useState<boolean>(false);
  const clearEvents = useCallback(() => {
    $("canvas").off("mousemove touchmove");
    $("canvas").off("mousedown touchstart");
    $("canvas").off("mouseup touchend");
    $("canvas").off("mouseup touchend");
    $("canvas").off("keydown");
    $("canvas").off("resize");
    $("canvas").off("dblclick");
    $(document).off("mousemove touchmove");
    $(document).off("mousedown touchstart");
    $(document).off("mouseup touchend");
    $(document).off("mouseup touchend");
    $(document).off("keydown");
    $(document).off("resize");
    $(document).off("dblclick");
  }, []);

  const executeScriptTerminated = () => {
    setGameState((state) => ({
      ...state,
      scriptTerminated: true,
    }));
  };

  const executeScriptInitiate = (data: any) => {
    setGameState((state) => ({
      ...state,
      scriptInitiate: data,
    }));
  };

  const [gameState, setGameState] = useState({
    stages: [] as Stage[],
    currentStage: {} as Stage,
    codigo,
    name,
    image,
    liveMode: null,
    scriptTerminated: false,
    scriptInitiate: false,
    executeScriptTerminated,
    executeScriptInitiate,
    confirmExit: false,
    setName,
    setImage,
    setEvents,
    setCodigo,
    setEndGame,
    events,
    clearEvents,
  });

  useEffect(() => {
    if (gameState?.currentStage?.id && pauseSceneState) {
      gameState.currentStage.pauseScene();
      setPauseSceneState(false);
    }
  }, [pauseSceneState, gameState]);

  const updateStages = useCallback((data: any) => {
    setGameState((state) => ({
      ...state,
      stages: data.stages,
    }));
  }, []);

  const updateCurrentStage = (currentStage: any) => {
    setGameState((state) => ({
      ...state,
      currentStage,
    }));
  };

  const pauseScene = () => setPauseSceneState(true);

  const getStage = (id: any): any => {
    let stage = gameState.stages.find((stage: any) => stage.id === id);
    return stage ? stage : gameState.stages?.[0];
  };

  const pauseStage = (id: any) => getStage(id).pauseScene();

  const pauseStages = useCallback(() => {
    gameState?.stages?.forEach((stage: any) => {
      stage.stopRenderCanvas();
      stage.pauseScene();
    });
  }, [gameState]);

  const createFromPreview = useCallback((preview: any, gameState) => {
    gameState.stages.map((stage: any) => {
      stage.acertosExecutados = 0;
      stage.stopRenderCanvas();
      stage.pauseScene();
      stage.liveMode = false;
      stage.init = true;
      stage.shapes = preview.stages?.find(
        (prevStage) => stage.id === prevStage.id
      )?.shapes;
      return stage;
    });

    const currentStage = gameState.stages[gameState.stages.length - 1];
    setEvents(currentStage);
    currentStage.start(true);
  }, []);

  const copyShape = useCallback(() => {
    if (gameState.currentStage.selectedShapes?.length > 0) {
      const shapesToCopy = gameState.currentStage.selectedShapes.map((shape) =>
        createClonedShape(shape)
      );
      if (shapesToCopy?.length) {
        copyToClipboard(JSON.stringify(shapesToCopy));
      }
    } else {
      const shape = createClonedShape(gameState.currentStage.shapeInUse);
      if (shape) {
        copyToClipboard(JSON.stringify([shape]));
      }
    }
  }, [gameState.currentStage]);

  const moveWithKeyPress = (key, evt, self) => {
    let deltaX = 0;
    let deltaY = 0;
    let shiftKey = 0;

    const _setDataMoveKey = () => {
      self?.shapeInUse?.setData([
        {
          info: "x",
        },
        {
          info: "posInitialX",
        },
        {
          info: "y",
        },
        {
          info: "posInitialY",
        },
      ]);
    };

    if (evt.shiftKey) {
      shiftKey = 9;
    }
    switch (key) {
      case 37: //left
        deltaX -= 10 - shiftKey;
        break;
      case 38: //up
        deltaY -= 10 - shiftKey;
        break;
      case 39: //right
        deltaX += 10 - shiftKey;
        break;
      case 40: //down
        deltaY += 10 - shiftKey;
        break;
      default:
        return;
    }
    if (self.shapeInUse) {
      _setDataMoveKey();
      self.shapeInUse.x += deltaX;
      self.shapeInUse.y += deltaY;
      self.shapeInUse.posInitialX += deltaX;
      self.shapeInUse.posInitialY += deltaY;
      self.createCorners();
    }
  };

  const removeShape = (event, currentStage) => {
    const _removeBondShape = (shape) => {
      const shapeToDelete = shape;
      const shapeIdToDeleted = shapeToDelete?.id;
      currentStage.shapes = currentStage.shapes.map((shape) => {
        if (shape.matchId?.includes(shapeIdToDeleted)) {
          currentStage.acertosDoCenario -= 1;
          shape.matchId = shape.matchId.filter((id) => id !== shapeIdToDeleted);
        }
        return shape;
      });
      if (currentStage.acertosDoCenario > 0) {
        currentStage.acertosDoCenario -=
          shapeToDelete.matchId?.length > 0 ? 1 : 0;
      }
      currentStage.shapes = currentStage.shapes.filter(
        (shape) => shape.id !== shapeIdToDeleted
      );
      currentStage.shapesDeleteds.push(shape);
      currentStage.corners.shapes = [];
      currentStage.shapeSelected = -1;
      const lastId = currentStage.getLastIdShape();
      if (lastId) {
        currentStage.corners.father = -1;
        const indexShape = currentStage.shapes.findIndex(
          (shape) => shape.id === lastId
        );
        currentStage.shapeInUse = currentStage.shapes[indexShape];
        currentStage.createCorners(indexShape);
      }
    };

    if (currentStage.looping) {
      if (event.keyCode === 46) {
        if (currentStage.selectedShapes?.length > 0) {
          for (const shape of currentStage.selectedShapes) {
            if (shape && shape?.id !== LIMIT_SHAPES) {
              _removeBondShape(shape);
              currentStage.clearSelectedGroupShapes();
            }
          }
        } else if (currentStage.corners.father !== -1) {
          const shape = currentStage.shapes[currentStage.corners.father];
          if (shape && shape?.id !== LIMIT_SHAPES) {
            _removeBondShape(shape);
          }
        }
      }
    }
  };

  const keyDownHandler = useCallback(
    (event) => {
      const key = event.keyCode;
      removeShape(event, gameState.currentStage);

      switch (key) {
        case 37:
        case 38:
        case 39:
        case 40:
          moveWithKeyPress(key, event, gameState.currentStage);
          break;
        case 67:
          if (event.ctrlKey) {
            event.preventDefault();
            copyShape();
          }
          break;
        case 86:
          if (event.ctrlKey) {
            event.preventDefault();

            pasteToClipBoard().then((response: any) => {
              try {
                const shapes = JSON.parse(response);
                if (shapes?.length) {
                  const { currentStage } = gameState;
                  currentStage.addShape(
                    shapes?.map((shape, index) =>
                      createClonedShape(
                        shape,
                        currentStage.getLastIdShape() + 1 + index
                      )
                    )
                  );
                }
              } catch (err) {}
            });
          }
          break;
        case 90:
          if (event.ctrlKey) {
            event.preventDefault();
            const { currentStage } = gameState;
            if (
              currentStage.shapeInUse?.id &&
              currentStage.shapeInUse?.states?.length > 0
            ) {
              const [data] = currentStage.shapeInUse.states.slice(-1);
              data.forEach(({ info, changeInfo, value }) => {
                currentStage.shapeInUse[info] = changeInfo ?? value;
                currentStage.createCorners();
              });
              currentStage.shapeInUse.states.pop();
            } else if (currentStage.shapesDeleteds.length) {
              const shapeRedo = currentStage.shapesDeleteds.slice(-1)[0];
              const shapeNotRedo = currentStage.shapes.find(
                (shape) => shape.id === shapeRedo?.id
              );
              if (shapeNotRedo) {
                message.info("Último estado atingido");
              } else if (shapeRedo) currentStage.shapes.push(shapeRedo);
              currentStage.shapesDeleteds.pop();
            }
          }
          break;
        default:
          return;
      }
    },
    [copyShape, gameState]
  );

  const draw = (game) => setGameState(() => ({ ...game }));

  const createStages = ({ stages }: any, isPlayTEA: boolean) => {
    const newStages: any = [];
    stages?.forEach((stageConstruct: any) => {
      let stage = new Stage(
        document.querySelector("canvas"),
        stageConstruct.id,
        stageConstruct.ids,
        [],
        stageConstruct.quandoIniciar,
        stageConstruct.quandoTerminar,
        Number(stageConstruct.acertosDoCenario),
        isPlayTEA
      );
      stageConstruct.shapes?.forEach((shape: any) => {
        let newShape = new Shape({
          ...shape,
          base64Image: shape.image64,
          image64: "",
        });
        stage.addShape([newShape]);
      });
      stage.setName(stageConstruct?.name);
      newStages.push(stage);
    });
    return newStages;
  };

  const create = (json: any, isPlayTEA: boolean) => {
    const { game } = json;
    return createStages(JSON.parse(game), isPlayTEA);
  };

  const data = {
    gameState,
    events,
    setGameState,
    pauseStage,
    getStage,
    create,
    createFromPreview,
    updateStages,
    updateCurrentStage,
    pauseScene,
    pauseStages,
    draw,
  };

  useEffect(() => {
    setGameState((state) => ({ ...state, image }));
  }, [image]);

  const nextStage = useCallback(
    (stage: any) => {
      return () => {
        updateCurrentStage(stage);
        stage.resizeResolution();
        stage.setLiveMode(true);
        stage.start(true, gameState);
        setEvents(stage);
      };
    },
    [gameState]
  );

  usePlayScriptShapes({
    endGame,
    gameState,
    nextStage,
    setGameState,
  });

  usePlayScriptTerminarShapes({
    endGame,
    gameState,
    nextStage,
    setGameState,
  });

  useEffect(() => {
    if (codigo && !gameState.codigo) setGameState({ ...gameState, codigo });
  }, [codigo, gameState]);

  useEffect(() => {
    if (image && !gameState.image) setGameState({ ...gameState, image });
  }, [image, gameState]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (events) {
      $(document).on("mousemove touchmove", (e) => {
        events?.moveAction(e);
      });
      $(document).on("mouseup touchend", (e) => {
        events?.dropAction(e, gameState);
      });
      $("canvas").on("mousedown touchstart", (e) => {
        const callBackDblClick = () => {
          events.openConfigs = true;
          events?.doubleClickAction?.(e, gameState);
          setGameState((state) => ({ ...state }));
        };

        events?.clickAction(e, callBackDblClick);
        setGameState((state) => ({
          ...state,
          click: true,
          dlbclick: false,
        }));
      });
      $(window).on("resize", (e) => {
        updateSizeCanvasDesign();
      });
    }

    return () => clearEvents();
  }, [events, clearEvents]);

  useEffect(() => {
    $(document).on("keydown", (e) => {
      keyDownHandler(e);
    });

    return () => {
      $("canvas").off("keydown");
      $(document).off("keydown");
    };
  }, [keyDownHandler]);

  return data;
};
