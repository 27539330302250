import React from "react";
import { Row, Col, Form, Input, DatePicker, Button, Upload } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import { Choose, If } from "react-extras";
import { LoginOutlined, UploadOutlined } from "@ant-design/icons";
import { RequiredField } from "../../required-field";
import { compressImage } from "pages/playtea-creator/create-game/canvas/utils/compress-image";

export const getBase64 = (file: Blob): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

interface Props {
  onSubmit: any;
  buttonLabel: string;
  management?: boolean;
  validationSchema: any;
  initialValues: any;
}

export const PersonRegistry = ({
  onSubmit,
  management,
  buttonLabel,
  initialValues,
  validationSchema,
}: Props) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values, resetForm) => onSubmit(values, resetForm),
    validationSchema,
  });

  const {
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
  } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col span={11}>
          <RequiredField>
            <div className="form-label">Nome</div>
          </RequiredField>
          <Form.Item
            hasFeedback
            validateStatus={touched.nome && errors.nome ? "error" : undefined}
            help={touched.nome && errors.nome}
          >
            <Input
              name="nome"
              value={values.nome}
              onChange={handleChange}
              tabIndex={1}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col span={11} offset={2}>
          <RequiredField>
            <div className="form-label">Data de nascimento</div>
          </RequiredField>
          <Form.Item
            hasFeedback
            validateStatus={
              touched.datanascimento && errors.datanascimento
                ? "error"
                : undefined
            }
            help={touched.datanascimento && errors.datanascimento}
          >
            <DatePicker
              style={{ width: "100%", height: "40px" }}
              name="datanascimento"
              format="DD/MM/YYYY"
              value={
                values.datanascimento
                  ? moment(values.datanascimento)
                  : values.datanascimento
              }
              disabledDate={(currentDate: any) => {
                const oldYear = moment().subtract("18", "years");
                return management && currentDate.isAfter(oldYear);
              }}
              onChange={(value) => {
                setFieldValue("datanascimento", value);
              }}
              tabIndex={1}
            />
          </Form.Item>
        </Col>

        <Choose>
          <Choose.When condition={Boolean(management)}>
            <Col span={24}>
              <RequiredField>
                <div className="form-label">E-mail</div>
              </RequiredField>
              <Form.Item
                hasFeedback
                validateStatus={
                  touched.email && errors.email ? "error" : undefined
                }
                help={touched.email && errors.email}
              >
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  tabIndex={1}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Choose.When>
          <Choose.Otherwise>
            <Col span={11}>
              <RequiredField>
                <div className="form-label">Usuário</div>
              </RequiredField>
              <Form.Item
                hasFeedback
                validateStatus={
                  touched.usuario && errors.usuario ? "error" : undefined
                }
                help={touched.usuario && errors.usuario}
              >
                <Input
                  name="usuario"
                  value={values.usuario}
                  onChange={handleChange}
                  tabIndex={1}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Choose.Otherwise>
        </Choose>

        <If condition={!management}>
          <Col span={11} offset={2}>
            <div className="form-label">Descrição</div>
            <Form.Item
              hasFeedback
              validateStatus={
                touched.descricao && errors.descricao ? "error" : undefined
              }
              help={touched.descricao && errors.descricao}
            >
              <Input.TextArea
                name="descricao"
                value={values.descricao}
                onChange={handleChange}
                tabIndex={1}
              />
            </Form.Item>
          </Col>
        </If>

        <Col span={11}>
          <RequiredField>
            <div className="form-label">Senha</div>
          </RequiredField>
          <Form.Item
            hasFeedback
            validateStatus={touched.senha && errors.senha ? "error" : undefined}
            help={touched.senha && errors.senha}
          >
            <Input.Password
              className="form-input"
              name="senha"
              value={values.senha}
              onChange={handleChange}
              tabIndex={2}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col span={11} offset={2}>
          <RequiredField>
            <div className="form-label">Confirmar senha</div>
          </RequiredField>
          <Form.Item
            hasFeedback
            validateStatus={
              touched.cpassword && errors.cpassword ? "error" : undefined
            }
            help={touched.cpassword && errors.cpassword}
          >
            <Input.Password
              className="form-input"
              name="cpassword"
              value={values.cpassword}
              onChange={handleChange}
              tabIndex={2}
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>

      <If condition={!management}>
        <Col span={24}>
          <div className="form-label">Foto</div>

          <Form.Item
            hasFeedback
            validateStatus={touched.foto && errors.foto ? "error" : undefined}
            help={touched.foto && errors.foto}
          >
            <Upload
              name={"foto"}
              listType={"picture"}
              accept={"image/*"}
              customRequest={() => true}
              fileList={
                values.foto
                  ? [
                      {
                        status: "done",
                        name: "Foto",
                        uid: "1",
                        size: 0,
                        type: "",
                        thumbUrl: values.foto,
                      },
                    ]
                  : []
              }
              onChange={async (info) => {
                const file = info.fileList[info.fileList.length - 1];
                if (file) {
                  file.status = "done";
                  setFieldValue(
                    "foto",
                    await getBase64(await compressImage(file.originFileObj))
                  );
                } else {
                  setFieldValue("foto", null);
                }
              }}
            >
              <Button>
                <UploadOutlined /> Upload
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </If>

      <Row justify="end">
        <Button
          htmlType="submit"
          icon={<LoginOutlined />}
          type="primary"
          size="large"
          style={{ marginTop: "30px" }}
          className="common--submit-button"
          tabIndex={3}
        >
          {buttonLabel}
        </Button>
      </Row>
    </form>
  );
};
