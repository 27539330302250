import { useMemo } from "react";
import {
  END_GAME,
  END_GAME_PLAY_TEA,
} from "../../../../../../../redux/games/actions";
import { useDispatch } from "react-redux";
import { loadAudio, sleep } from "../utils";
import { PAINEL_ACTIONS_SCRIPTS_NAME, SCRIPT_NAMES } from "utils/enums";

export const usePlayScriptShapes = ({
  gameState,
  setGameState,
  nextStage,
  endGame,
}) => {
  const dispatch = useDispatch();

  //playScript Shapes
  useMemo(async () => {
    if (
      gameState.scriptInitiate &&
      !gameState.executing &&
      gameState.currentStage.liveMode
    ) {
      setGameState((state) => ({
        ...state,
        scriptInitiate: false,
        executing: true,
      }));
      const { currentStage } = gameState;
      const {
        type: identifier,
        script,
        shapeSelected,
        name,
        init = true,
      }: any = gameState.scriptInitiate;
      const currentShape = currentStage.shapes.find(
        (shape) => shape.id === shapeSelected?.id ?? currentStage.shapeInUse?.id
      );

      const _startStage = async (stage) => {
        currentStage.stopRenderCanvas();
        currentStage.pauseScene();
        currentStage.acertosExecutados = 0;
        nextStage(stage)();
      };

      currentStage.init = init;
      for (let i = 0; i < identifier.length; i++) {
        switch (script[identifier[i]].type) {
          case PAINEL_ACTIONS_SCRIPTS_NAME.NEW_IMAGE:
            currentShape.hidden = false;
            currentShape.image64 = script[identifier[i]].value;
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.NEW_AUDIO:
            await loadAudio(script[identifier[i]].value);
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.END_GAME:
            const quandoTerminar = Object.keys(currentStage.quandoTerminar);
            currentStage.init = false;
            if (quandoTerminar.length) {
              await currentStage.playScriptTerminar(-1, gameState);
            }
            dispatch({
              type: endGame ? END_GAME_PLAY_TEA : END_GAME,
              payload: currentStage.clock,
            });
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.NEXT_STAGE:
            const nextIndex = gameState.stages.findIndex(
              (value) => value.id === currentStage.id
            );
            const nextStage = gameState.stages?.[nextIndex + 1];
            currentStage.init = false;
            if (nextStage) {
              let quandoTerminar = Object.keys(currentStage.quandoTerminar);
              if (quandoTerminar.length)
                await currentStage.playScriptTerminar(nextStage.id, gameState);
              else {
                await _startStage(nextStage);
              }
            } else {
              dispatch({
                type: endGame ? END_GAME_PLAY_TEA : END_GAME,
                payload: currentStage.clock,
              });
            }
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.PREVIOUS_STAGE:
            const prevIndex = gameState.stages.findIndex(
              (value) => value.id === currentStage.id
            );
            const prevStage = gameState.stages?.[prevIndex - 1];
            currentStage.init = false;
            if (prevStage) {
              let quandoTerminar = Object.keys(currentStage.quandoTerminar);
              if (quandoTerminar.length)
                await currentStage.playScriptTerminar(prevStage.id, gameState);
              else {
                currentStage.shapeSelected = -1;
                await _startStage(prevStage);
              }
            } else {
              dispatch({
                type: endGame ? END_GAME_PLAY_TEA : END_GAME,
                payload: currentStage.clock,
              });
            }
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.SPECIFY_STAGE:
            let specify = gameState?.stages.find(
              (stage) => stage.id === script[identifier[i]].value
            );
            if (specify) {
              let quandoTerminar = Object.keys(currentStage.quandoTerminar);
              if (quandoTerminar.length)
                await currentStage.playScriptTerminar(
                  script[identifier[i]].value,
                  gameState
                );
              else {
                await _startStage(specify);
              }
            }
            currentStage.init = false;
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.SHOW_SHAPE:
            let shapeShow = currentStage.shapes.find(
              (shape) => shape.id === script[identifier[i]].value
            );
            if (shapeShow) shapeShow.hidden = false;
            break;
          case PAINEL_ACTIONS_SCRIPTS_NAME.HIDE_SHAPE:
            let shapeHidden = currentStage.shapes.find(
              (shape) => shape.id === script[identifier[i]].value
            );
            shapeHidden.hidden = true;
            break;

          case PAINEL_ACTIONS_SCRIPTS_NAME.AWAITING_TIME:
            await sleep(script[identifier[i]].value * 1000);
            break;
          default:
            return;
        }
        await sleep(300);
      }

      currentStage.init = true;
      if (
        [
          SCRIPT_NAMES.QUANDO_CLICAR,
          SCRIPT_NAMES.QUANDO_CLICAR,
          SCRIPT_NAMES.QUANDO_ERRAR,
        ].includes(name)
      )
        currentStage.shapeInUse = {};

      setGameState((state) => ({
        ...state,
        executing: false,
      }));
    }
  }, [gameState, setGameState, nextStage, dispatch, endGame]);
};
