import React, { createContext, useEffect, useState } from "react";
import "./css/chat.css";
import { Canvas } from "./canvas";
import { Load } from "./canvas/game/load";
import { useLocation } from "react-router-dom";
import { useScreenOrientation } from "../../../hooks/useScreenOrientation";
import { PostMessagesTypes, ScreenOrientation } from "../../../enums";
import { Choose } from "react-extras";
import { LoaderBlock } from "../../../components/loader-block";

const sleep = (timer: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(timer), timer);
  });
};

export const LoadContext = createContext<any>({});

export const CreateGamePlayTEA = () => {
  const location: any = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  useScreenOrientation({ orientation: ScreenOrientation.LANDSCAPE });

  useEffect(() => {
    const reloaded = localStorage.getItem("reload-run-game");
    const eventHandler = async (event) => {
      if (event.data.type === PostMessagesTypes.ScreenOrientation) {
        await sleep(1000);
        setLoading(false);
      }
    };
    window.addEventListener("message", eventHandler);
    if (reloaded !== "true") {
      localStorage.setItem("reload-run-game", "true");
      window.location.reload();
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      window.removeEventListener("message", eventHandler);
    };
  }, []);

  return (
    <Choose>
      <Choose.When condition={loading}>
        <LoaderBlock spinning text="Carregando jogo" />
      </Choose.When>
      <Choose.Otherwise>
        <Load
          liveMode
          isPlayTEA
          endGameOnFinish
          selectedGame={location.state?.game}
        >
          <Canvas />
        </Load>
      </Choose.Otherwise>
    </Choose>
  );
};
