import React, { useCallback, useEffect, useState } from "react";
import { Layout } from "components/layout";
import { Row, Col, Card, message, Spin, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import PupilsService from "redux/pupils";
import { bufferToBase64 } from "utils/base64";
import { Choose, If } from "react-extras";
import { useSelector } from "react-redux";
import { LoaderBlock } from "components/loader-block";
import { useScreenOrientation } from "hooks/useScreenOrientation";
import { ScreenOrientation } from "enums";
import { CONTEXTS } from "utils/enums";
import GamesService from "redux/games/games";
import { dBAccess } from "App";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import "./styles.less";

const _createKeyPair = ({ game, student }: any) =>
  `g=${game.codigo}&s=${game.aluno}&t=${student.tutor}`;

const getLocalGames = (student: any, code?: number) => {
  return dBAccess().then(async (db: any) => {
    const tx = db.transaction("games", "readwrite");
    const store = tx.objectStore("games");
    const gamesPromise = (await store).getAll();
    return new Promise((resolve, reject: any) => {
      gamesPromise.onsuccess = () => {
        const data = gamesPromise.result;
        const games = data.filter(
          (game: any) => game.keyPair === _createKeyPair({ game, student }),
        );
        if (games?.length > 0) {
          if (code) {
            const game = games.find((g) => g.codigo === code);

            if (game) {
              return resolve(game);
            }

            return reject();
          }

          resolve(games);
        } else reject();
      };
    });
  });
};

const createLocalGame = (store: any, student: any) => (game) => {
  store.put({
    ...game,
    keyPair: _createKeyPair({ game, student }),
  });
};

const updateLocalGame = (game: any, student: any) => {
  dBAccess().then(async (db: any) => {
    const tx = db.transaction("games", "readwrite");
    const store = tx.objectStore("games");
    createLocalGame(store, student)(game);
    return store;
  });
};

const createLocalGames = (games: any, student: any) => {
  dBAccess().then(async (db: any) => {
    const tx = db.transaction("games", "readwrite");
    const store = tx.objectStore("games");

    const localGames: any = await getLocalGames(student);

    console.log(localGames);
    games.forEach((game) => {
      const localGame = localGames.find(
        (lGame) => lGame.codigo === game.codigo,
      );

      if (!localGame?.game) {
        createLocalGame(store, student);
      }
    });
    return store;
  });
};

export const generateCardGames = ({
  games,
  loadingCard,
  historyAction,
  playteaStudentComps,
  playteaCreatorComps,
}: any) => {
  return games.map((game: any) => {
    game.foto = game?.foto?.data
      ? atob(bufferToBase64(game.foto.data)).replace("./images", "")
      : game.foto;
    return (
      <Col key={game.codigo} style={{ minHeight: 500, marginTop: 80 }}>
        <Card
          key={game.codigo}
          hoverable
          style={{
            width: 300,
            minHeight: 400,
            textAlign: "center",
            marginBottom: "20px",
          }}
          cover={
            <Spin spinning={loadingCard === game.codigo}>
              <img
                alt="example"
                src={game.foto}
                style={{ maxHeight: 327, minHeight: 327, maxWidth: "100%" }}
              />
            </Spin>
          }
          onClick={() => historyAction(game)}
        >
          <Card.Meta title={game.nome} description={game.descricao} />
        </Card>
        <Choose>
          <Choose.When condition={Boolean(playteaCreatorComps)}>
            {playteaCreatorComps?.(game)}
          </Choose.When>
          <Choose.Otherwise>{playteaStudentComps?.(game)}</Choose.Otherwise>
        </Choose>
      </Col>
    );
  });
};

export const StudentDetails = () => {
  const history = useHistory();
  const { studentReducer }: any = useSelector((state) => state);
  const { student } = studentReducer;
  const [games, setGames]: any = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCard, setLoadingCard] = useState<boolean>(false);
  const [downloadingGame, setDownloadingGame] = useState<number>();

  const isOnline = window.navigator.onLine;

  useScreenOrientation({ orientation: ScreenOrientation.PORTRAIT });

  const getGames = useCallback(
    async (codigo: any, onLine: any) => {
      setLoading(true);
      try {
        if (onLine) {
          const {
            data: { result },
          } = await PupilsService.games(codigo, false);

          createLocalGames(result, student);
          setGames(result);
        } else {
          setGames(await getLocalGames(student));
        }
      } finally {
        setLoading(false);
      }
    },
    [student],
  );

  const saveMetrics = useCallback(
    async (codigo: any, times: number, type: string, metric_id: number) => {
      await PupilsService.saveMetrics(codigo, metric_id, times).catch(() => "");
      localStorage.removeItem(type);
    },
    [],
  );

  useEffect(() => {
    getGames(student?.codigo, isOnline);
    if (isOnline) {
      const timePlayed = localStorage.getItem("HEA");
      if (timePlayed)
        saveMetrics(student?.codigo, Number(timePlayed), "HEA", 1);

      const finishActivities = localStorage.getItem("AF");
      if (finishActivities)
        saveMetrics(student?.codigo, Number(finishActivities), "AF", 3);

      const notSavedActivities = localStorage.getItem("ANF");
      if (notSavedActivities) {
        saveMetrics(student?.codigo, Number(notSavedActivities), "ANF", 2);
      }
    }
  }, [student, getGames, saveMetrics, isOnline]);

  return (
    <Layout hasHeader isPlayTEA theme="light">
      <Row
        gutter={24}
        justify="center"
        style={{ display: "flex", marginRight: "0px", marginLeft: "0px" }}
      >
        <Choose>
          <Choose.When condition={loading}>
            <LoaderBlock text="Buscando Jogos" spinning={loading} />
          </Choose.When>
          <Choose.When condition={Boolean(games.length)}>
            {generateCardGames({
              games,
              student,
              loadingCard,
              playteaStudentComps: (game) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "end",
                      marginTop: "-15px",
                    }}
                  >
                    <If condition={isOnline}>
                      <Tooltip
                        title={`Baixar para jogar offline: ${game.nome}`}
                      >
                        <Choose>
                          <Choose.When
                            condition={downloadingGame === game.codigo}
                          >
                            <LoadingOutlined />
                          </Choose.When>
                          <Choose.Otherwise>
                            <DownloadOutlined
                              className="icon-trash"
                              onClick={() => {
                                setDownloadingGame(game.codigo);
                                GamesService.get(student?.codigo, game.codigo)
                                  .then(({ data }) => {
                                    if (data.result) {
                                      message.success(
                                        "Download realizado com sucesso, agora você pode jogar esse jogo quando não estiver conectado na internet",
                                      );
                                      return updateLocalGame(
                                        data.result,
                                        student,
                                      );
                                    }

                                    message.warn(
                                      "Não foi possivel buscar as informações do jogo, tente novamente",
                                    );
                                  })
                                  .catch(() => {
                                    message.warn(
                                      "Não foi possivel buscar as informações do jogo, tente novamente",
                                    );
                                  })
                                  .finally(() => {
                                    setDownloadingGame(undefined);
                                  });
                              }}
                            />
                          </Choose.Otherwise>
                        </Choose>
                      </Tooltip>
                    </If>
                  </div>
                );
              },
              historyAction: async (gameProps) => {
                setLoadingCard(gameProps.codigo);
                if (isOnline) {
                  GamesService.get(student?.codigo, gameProps.codigo)
                    .then(({ data }) => {
                      if (data?.result?.game) {
                        localStorage.setItem("reload-run-game", "false");
                        return history.push(`${CONTEXTS.PLAYTEA}/run`, {
                          game: data.result,
                        });
                      }

                      message.warn(
                        "Não foi possivel buscar as informações do jogo, tente novamente",
                      );
                    })
                    .catch(() => {
                      message.warn(
                        "Não foi possivel buscar as informações do jogo, tente novamente",
                      );
                    })
                    .finally(() => {
                      setLoadingCard(false);
                    });
                } else if (gameProps.game) {
                  localStorage.setItem("reload-run-game", "false");
                  return history.push(`${CONTEXTS.PLAYTEA}/run`, {
                    game: gameProps,
                  });
                }
              },
            })}
          </Choose.When>
          <Choose.Otherwise>
            <div>Você não possui nenhum jogo no momento</div>
          </Choose.Otherwise>
        </Choose>
      </Row>
    </Layout>
  );
};
