import React, { useContext, useEffect } from "react";
import { PainelAttrs } from "./canvas/components/painel-attrs-shape";
import { PainelAttrsStage } from "./canvas/components/painel-attrs-stage";
import { GameContext } from ".";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_SWIPES } from "../../../redux/games/actions";
import { Choose } from "react-extras";
export const CanvasOptions = ({
  canvasOptions,
  showCanvasOptions,
}: {
  canvasOptions: boolean;
  showCanvasOptions: any;
}) => {
  const { game } = useContext(GameContext);
  const { gameState } = game;
  const { gameReducer }: any = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !gameState.currentStage?.liveMode &&
      (gameState.currentStage.openConfigs ||
        (gameReducer.swipeLeft && !gameState.currentStage.shapeInUse?.id))
    ) {
      showCanvasOptions(true);
      gameState.currentStage.openConfigs = false;
      dispatch({
        type: CLEAR_SWIPES,
      });
    }
  }, [gameState, gameReducer, dispatch, showCanvasOptions]);

  const blurPanelsStyle: any = gameState.currentStage?.liveMode
    ? { filter: "blur(5px)", pointerEvents: "none", opacity: "0.4" }
    : {};

  return (
    <Choose>
      <Choose.When condition={Boolean(gameState.currentStage?.shapeInUse?.id)}>
        <div id="content-wrapper" style={blurPanelsStyle}>
          <PainelAttrs />
        </div>
      </Choose.When>
      <Choose.Otherwise>
        <div id="content-wrapper" style={blurPanelsStyle}>
          <PainelAttrsStage />
        </div>
      </Choose.Otherwise>
    </Choose>
  );
};
