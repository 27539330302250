import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  END_GAME,
  END_GAME_PLAY_TEA,
} from "../../../../../../../redux/games/actions";
import { loadAudio, sleep } from "../utils";

export const usePlayScriptTerminarShapes = ({
  gameState,
  setGameState,
  nextStage,
  endGame,
}) => {
  const dispatch = useDispatch();
  //playScriptTerminar Shapes
  useMemo(async () => {
    let value = -1;
    const { currentStage } = gameState;
    const _startStage = async (stage: any) => {
      currentStage.stopRenderCanvas();
      currentStage.pauseScene();
      currentStage.acertosExecutados = 0;
      nextStage(stage)();
    };
    if (gameState.scriptTerminated) {
      let quandoTerminar: any = Object.keys(currentStage.quandoTerminar);

      setGameState((state) => ({
        ...state,
        scriptTerminated: false,
        endScriptTerminated: true,
      }));

      for (let i = 0; i < quandoTerminar.length; i++) {
        const QuandoTerminarAction =
          currentStage.quandoTerminar[quandoTerminar[i]];
        let shapeToAction = currentStage.shapes.find(
          (shape) => shape.id === QuandoTerminarAction.value,
        );
        switch (QuandoTerminarAction.type) {
          case "image":
            if (!shapeToAction.img) shapeToAction.img = new Image();
            shapeToAction.img.src = QuandoTerminarAction.value;
            break;
          case "sound":
            await loadAudio(QuandoTerminarAction.value);
            break;
          case "end":
            value = -1;
            break;
          case "next":
            const nextIndex = gameState.stages.findIndex(
              (value) => value.id === currentStage.id,
            );
            const nextStage = gameState.stages?.[nextIndex + 1];
            value = nextStage?.id;
            break;
          case "previous":
            const prevIndex = gameState.stages.findIndex(
              (value) => value.id === currentStage.id,
            );
            const prevStage = gameState.stages?.[prevIndex - 1];
            value = prevStage?.id;
            break;
          case "specify":
            value = QuandoTerminarAction.value;
            break;

          case "show":
            if (shapeToAction) {
              shapeToAction.hidden = false;
            }
            break;
          case "hide":
            if (shapeToAction) {
              shapeToAction.hidden = true;
            }
            break;
          case "sleep":
            await sleep(QuandoTerminarAction.value * 1000);
            break;
          default:
            break;
        }
      }
      let stage = gameState.stages.find((stage) => stage.id === value);
      if (stage) {
        await _startStage(stage);
      } else {
        dispatch({
          type: endGame ? END_GAME_PLAY_TEA : END_GAME,
          payload: currentStage.clock,
        });
      }
      setGameState((state) => ({
        ...state,
        endScriptTerminated: false,
      }));
    }
  }, [gameState, setGameState, nextStage, dispatch, endGame]);
};
