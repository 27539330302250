import React from "react";
import { Layout } from "../../../components/layout";
import { Breadcrumb, Row, Col, Card, Divider, Typography } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CONTEXTS } from "../../../utils/enums";
import "./styles.less";
import { isMobile } from "react-device-detect";

const mecanics = [
  {
    id: 1,
    image: "/pareamento.jpg",
    name: "Clicar, Arrastar e Soltar",
    description: "",
    onClick: (history, id, game = {}) => {
      localStorage.setItem("reload-create-game", "false");
      history.push(`${CONTEXTS.PLAYTEA_CREATOR}/alunos/${id}/jogos/newGame`, {
        game,
      });
    },
  },
  {
    id: 2,
    image: "/em-breve.jpg",
    name: "Arremessar",
    disabled: true,
    description: "",
  },
  {
    id: 3,
    image: "/em-breve.jpg",
    name: "Colorir",
    disabled: true,
    description: "",
  },
  {
    id: 4,
    image: "/em-breve.jpg",
    name: "Seguir",
    disabled: true,
    description: "",
  },
];

export const Mecanics = () => {
  const history = useHistory();
  const parameters: any = useParams();
  const { studentReducer }: any = useSelector((state) => state);
  const { student } = studentReducer;

  return (
    <Layout
      hasHeader
      theme="light"
      style={{ minHeight: window.innerHeight + ((isMobile && 200) || 0) }}
    >
      <Breadcrumb style={{ display: "flex", justifyContent: "center" }}>
        <Breadcrumb.Item>
          <Link to={CONTEXTS.PLAYTEA_CREATOR}>Home</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to={`${CONTEXTS.PLAYTEA_CREATOR}/alunos`}>Alunos</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{student?.nome || "Aluno"}</Breadcrumb.Item>
        <Breadcrumb.Item>Mecanicas</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <Row justify="center" align="middle">
        <Typography.Text strong style={{ fontSize: 24 }}>
          Mecânicas
        </Typography.Text>
      </Row>
      <Row
        gutter={24}
        justify="center"
        align="middle"
        style={{ display: "flex", marginRight: "0px", marginLeft: "0px" }}
      >
        {mecanics.map((mecanic) => (
          <Col key={mecanic.id} style={{ marginTop: 40, marginBottom: 35 }}>
            <Card
              className="card-mecanics"
              key={mecanic.id}
              hoverable={!mecanic.disabled}
              style={{
                width: 300,
                maxHeight: 200,
                textAlign: "center",
                marginBottom: "20px",
              }}
              cover={
                <img
                  style={{ maxHeight: 200, minHeight: 200 }}
                  alt="mecanic"
                  src={mecanic.image}
                />
              }
              onClick={() =>
                mecanic.onClick?.(history, student?.codigo || parameters?.id)
              }
            >
              <Card.Meta
                style={{ backgroundColor: "white" }}
                title={mecanic.name}
                description={mecanic.description}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </Layout>
  );
};
