import { ENCRYPT_PASSWORD } from "common/constants";
import { AES, enc } from "crypto-js";

const cryptConfig = {
  algorithm: "aes256",
  secret: ENCRYPT_PASSWORD,
  type: "hex",
};

export const encrypt = (data, secret?: string) => {
  return AES.encrypt(
    enc.Base64.stringify(enc.Utf8.parse(data)),
    secret ?? cryptConfig.secret
  ).toString();
};

export const decrypt = (data, secret?: string) => {
  const decrypt = AES.decrypt(data, secret ?? cryptConfig.secret);
  const bytes = decrypt.toString(enc.Utf8);
  const decodedB64 = enc.Base64.parse(bytes);
  return enc.Utf8.stringify(decodedB64);
};
