import React, { useEffect, useState } from "react";
import { Layout } from "../../../components/layout";
import { Breadcrumb, Row, Button, Tooltip, message, Upload, Modal } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import PupilsService from "../../../redux/pupils";
import GamesService from "../../../redux/games/games";
import {
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ExportOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Choose } from "react-extras";
import { useSelector } from "react-redux";
import { LoaderBlock } from "../../../components/loader-block";
import { useScreenOrientation } from "../../../hooks/useScreenOrientation";
import { ScreenOrientation } from "../../../enums";
import { CONTEXTS } from "../../../utils/enums";
import { generateCardGames } from "../../playtea/student-details";
import { PasswordExportModal } from "components/modals/password-export-modal";
import "./styles.less";
import { decrypt } from "utils/crypto";
import { PasswordImportModal } from "components/modals/password-import-modal";
import { dataSaveGame } from "../create-game/utils";

export const CreatorStudentDetails = () => {
  const history = useHistory();
  const parameters: any = useParams();
  const { studentReducer }: any = useSelector((state) => state);
  const { student } = studentReducer;
  const [games, setGames] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [modalPasswordExport, showModalPasswordExport] = useState(false);
  const [modalConfirmPasswordImport, showModalConfirmPassword] = useState(
    false
  );

  const [loadingCard, setLoadingCard] = useState(false);

  const newGameButton = (
    <Button
      style={{
        fontWeight: 700,
        fontSize: 16,
        height: "47px",
        marginRight: "20px",
      }}
      disabled={false}
      type="primary"
      size="large"
      className="common--submit-button"
      onClick={() =>
        history.push(
          `${CONTEXTS.PLAYTEA_CREATOR}/alunos/${
            student?.codigo || parameters?.id
          }/jogos/mecanicas`,
          { game: {} }
        )
      }
    >
      Novo jogo
    </Button>
  );

  useScreenOrientation({ orientation: ScreenOrientation.PORTRAIT });

  const importGameButton = (
    <Upload
      accept="text/plain"
      className="common--submit-button"
      fileList={[]}
      customRequest={() => "ok"}
      onChange={(data: any) => {
        const {
          file: { originFileObj },
        } = data;
        const reader = new FileReader();
        reader.addEventListener("loadend", async (event: any) => {
          const gameImported = event.target?.result;
          showModalConfirmPassword(gameImported);
        });
        reader.readAsText(originFileObj as any, "UTF-8");
      }}
    >
      Importar Jogo
    </Upload>
  );

  const visibilityGame = async (game: any) => {
    const currentGame = games.find((g: any) => g.codigo === game.codigo);
    currentGame.disable = currentGame.disable === 0 ? 1 : 0;
    try {
      await GamesService.updateEyeVisibility(game.codigo, currentGame.disable);
      setGames([...games]);
      message.success(
        `Jogo ${
          currentGame.disable === 0 ? "habilitado" : "desabilitado"
        } para ${student?.nome} com sucesso.`
      );
    } catch {
      message.error("Jogo não foi habilitado/desabilitado, tente novamente");
    }
  };

  useEffect(() => {
    getGames(parameters?.id);
  }, [parameters]);

  const getGames = async (codigo: any) => {
    setLoading(true);
    const {
      data: { result },
    } = await PupilsService.games(codigo);
    setGames(result);
    setLoading(false);
  };

  const deleteGame = (game: any) => {
    Modal.info({
      title: "Deletar",
      content: `Tem certeza que deseja deletar o jogo ${game.nome}?`,
      cancelText: "Voltar",
      okText: "Excluir",
      okButtonProps: {
        className: "button-delete",
      },
      okCancel: true,
      onOk: async () => {
        try {
          await GamesService.remove(
            student?.codigo ?? parameters?.id,
            game.codigo
          );
          getGames(parameters?.id);
          message.success(`Jogo ${game.nome} deletado com sucesso.`);
        } catch {
          message.error(
            `Não foi possivel excluir o jogo ${game.nome}, tente novamente`
          );
        }
      },
    });
  };

  const fetchGame = (gameProps, setLoader, callback) => {
    setLoader(gameProps.codigo);
    GamesService.get(student?.codigo ?? parameters?.id, gameProps.codigo)
      .then((response) => {
        const gameProps = response.data.result;
        return callback(gameProps);
      })
      .catch(() => {
        message.warn(
          "Nao foi possivel buscar as informacoes do jogo, tente novamente"
        );
      })
      .finally(() => {
        setLoader("");
      });
  };

  const tryImportGame = async (password: string) => {
    try {
      setLoading(true);
      const decryptedData = JSON.parse(
        decrypt(modalConfirmPasswordImport, password)
      );
      const { formData }: any = dataSaveGame({
        data: decryptedData,
        pupilCode: parameters?.id,
        imageGame: decryptedData?.foto,
      });
      if (formData) {
        await GamesService.create(
          formData,
          "newgame",
          student.codigo ?? parameters?.id
        );
      }
      getGames(parameters?.id);
      message.success("Jogo importado com sucesso.");
    } catch {
      message.error("Não foi possível importar o jogo, tente novamente.");
    } finally {
      showModalConfirmPassword(false);
      setLoading(false);
    }
  };

  return (
    <Layout hasHeader theme="light">
      <Breadcrumb style={{ display: "flex", justifyContent: "center" }}>
        <Breadcrumb.Item>
          <Link to={CONTEXTS.PLAYTEA_CREATOR}>Home</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to={`${CONTEXTS.PLAYTEA_CREATOR}/alunos`}>Alunos</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{student?.nome || "Aluno"}</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={24} justify="center" style={{ marginBottom: "40px" }}>
        {newGameButton}
        {importGameButton}
      </Row>
      <Row
        gutter={24}
        justify="center"
        style={{ display: "flex", marginRight: "0px", marginLeft: "0px" }}
      >
        <Choose>
          <Choose.When condition={loading}>
            <LoaderBlock text="Buscando Jogos" spinning={loading} />
          </Choose.When>
          <Choose.When condition={Boolean(games.length)}>
            {generateCardGames({
              loadingCard,
              playteaCreatorComps: (gameProps) => (
                <Row justify="end">
                  <Tooltip title={`Exportar jogo: ${gameProps.nome}`}>
                    <Choose>
                      <Choose.When
                        condition={loadingExport === gameProps.codigo}
                      >
                        <LoadingOutlined />
                      </Choose.When>
                      <Choose.Otherwise>
                        <ExportOutlined
                          className="icon-trash"
                          onClick={() => {
                            showModalPasswordExport(gameProps);
                          }}
                        />
                      </Choose.Otherwise>
                    </Choose>
                  </Tooltip>
                  <Tooltip title={`Deletar jogo: ${gameProps.nome}`}>
                    <DeleteOutlined
                      className="icon-trash"
                      onClick={async () => {
                        deleteGame(gameProps);
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    title={`Habilitar/desabilitar jogo: ${gameProps.nome}`}
                  >
                    {gameProps.disable === 0 ? (
                      <EyeOutlined
                        className="icon-edit"
                        onClick={() => visibilityGame(gameProps)}
                      />
                    ) : (
                      <EyeInvisibleOutlined
                        className="icon-edit"
                        onClick={() => visibilityGame(gameProps)}
                      />
                    )}
                  </Tooltip>
                </Row>
              ),
              games,
              historyAction: (gameProps) => {
                fetchGame(gameProps, setLoadingCard, (data) => {
                  if (data.game) {
                    localStorage.setItem("reload-create-game", "false");
                    history.push(
                      `${CONTEXTS.PLAYTEA_CREATOR}/alunos/${
                        student?.codigo || parameters?.id
                      }/jogos/${data.codigo}`,
                      { game: data }
                    );
                  } else {
                    message.warn(
                      "Não foi possivel buscar as informações do jogo, tente novamente"
                    );
                  }
                });
              },
            })}
          </Choose.When>
          <Choose.Otherwise>
            <div>Você ainda não possui nenhum jogo criado.</div>
          </Choose.Otherwise>
        </Choose>
      </Row>
      <PasswordExportModal
        visible={Boolean(modalPasswordExport)}
        gameProps={modalPasswordExport}
        setVisible={showModalPasswordExport}
        setLoadingExport={setLoadingExport}
        studentCode={student?.codigo ?? parameters?.id}
      />

      <PasswordImportModal
        visible={Boolean(modalConfirmPasswordImport)}
        loading={loading}
        setVisible={showModalConfirmPassword}
        callBack={async (password) => tryImportGame(password)}
      />
    </Layout>
  );
};
