import { combineReducers } from "redux";

import {
  LOGIN_MANAGEMENT,
  LOGOUT_MANAGEMENT,
  CLEAN_MANAGEMENT_STATE,
  REMOVE_FLAG_TEMPORALY_PASSWORD,
} from "./actions";

export const defaultManagementState = {};

export function management(state = defaultManagementState, action) {
  switch (action?.type) {
    case LOGIN_MANAGEMENT: {
      const { payload } = action;
      return {
        ...state,
        isSaved: true,
        ...payload,
      };
    }

    case REMOVE_FLAG_TEMPORALY_PASSWORD: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }

    case CLEAN_MANAGEMENT_STATE:
    case LOGOUT_MANAGEMENT: {
      return {
        ...defaultManagementState,
      };
    }

    default:
      return state;
  }
}

export const managementReducer = combineReducers({ management });
