import { axiosInstance } from "../../common/app";
import { APP_API_ROOT } from "../../common/constants";

class GamesService {
  async getScene(code: number) {
    return axiosInstance.get(`${APP_API_ROOT}/jogos/scenes/${code}`);
  }

  async createScene(data: any) {
    return axiosInstance.post(`${APP_API_ROOT}/jogos/scene`, data);
  }

  async scenes(tutorCode: string, disable = 0, queryType?: string) {
    return axiosInstance.get(`${APP_API_ROOT}/jogos/${tutorCode}/scenes`, {
      params: { disable, queryType },
    });
  }

  async updateEyeVisibility(id: number, disable: any, isScene?: boolean) {
    if (isScene) {
      return axiosInstance.put(
        `${APP_API_ROOT}/jogos/scenes/${id}/only-visible`,
        { disable }
      );
    }

    return axiosInstance.put(`${APP_API_ROOT}/jogos/${id}/only-visible`, {
      disable,
    });
  }

  async create(data: any, code: any, student: string) {
    code = Number(code);

    if (Number.isNaN(code)) {
      return axiosInstance.post(`${APP_API_ROOT}/aluno/${student}/jogos`, data);
    }

    return axiosInstance.put(
      `${APP_API_ROOT}/aluno/${student}/jogos/${code}`,
      data
    );
  }

  async removeScene(code: number) {
    return axiosInstance.delete(`${APP_API_ROOT}/jogos/scenes/${code}`);
  }

  // with student
  async update(student: number, code: number, data: any) {
    return axiosInstance.put(`${APP_API_ROOT}//${student}/jogos/${code}`, data);
  }

  async get(student: string, code: number) {
    return axiosInstance.get(`${APP_API_ROOT}/aluno/${student}/jogos/${code}`);
  }

  async remove(student: string, code: number) {
    return axiosInstance.delete(
      `${APP_API_ROOT}/aluno/${student}/jogos/${code}`
    );
  }
}

export default new GamesService();
