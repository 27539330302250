import { useCallback, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { PostMessagesTypes, ScreenOrientation } from "../enums";

interface Props {
  orientation: number;
}

const newWindow: any = window;

function zoomOutMobile(scale) {
  const viewport: any = document.querySelector('meta[name="viewport"]');
  if (viewport) {
    viewport.content = `initial-scale=${scale}`;
  }
}

export const useScreenOrientation = ({ orientation }: Props) => {
  const lockOrientation = useCallback(async () => {
    if (isMobile && newWindow?.ReactNativeWebView) {
      newWindow?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: PostMessagesTypes.ScreenOrientation,
          orientation,
        }),
      );

      if (orientation === ScreenOrientation.LANDSCAPE)
        return zoomOutMobile("0.1");

      zoomOutMobile("1.0");
    }
  }, [orientation]);

  useEffect(() => {
    lockOrientation();
  }, [lockOrientation, orientation]);
};
