import React, { FC, ReactElement } from "react";
import { initDB } from "react-indexed-db";
import { Routes } from "./routes/Routes";

import "./styles/app.less";

const dbName = "playtea-storage";
export const DBConfig: any = {
  name: dbName,
  version: 1,
  objectStoresMeta: [
    {
      store: "games",
      storeConfig: { keyPath: "keyPair", autoIncrement: false },
      storeSchema: [
        {
          document: "keyPair",
          keypath: "keyPair",
          options: { unique: true, index: true },
        },
      ],
    },
  ],
};

export const dBAccess = async () => {
  return new Promise((resolve) => {
    const dbRequest = indexedDB.open(dbName, 1);
    dbRequest.onsuccess = (event: any) => {
      const db = event.target.result;
      resolve(db);
    };
  });
};

initDB(DBConfig);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then(function (registration) {
      console.log(
        "Service Worker registration successful with scope: ",
        registration.scope,
      );
    })
    .catch(function (err) {
      console.log("Service Worker registration failed: ", err);
    });
}

export const App: FC = (): ReactElement => {
  return <Routes />;
};
