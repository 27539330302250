import React from "react";
import { Modal, Form, Input, Button, Row } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Digite um e-mail válido"),
});

interface Props {
  visible: boolean;
  toggleForgotPassModal: (data: boolean) => void;
  onFormSubmit: any;
}

export const ChangePasswordModal = ({
  visible,
  toggleForgotPassModal,
  onFormSubmit,
}: Props) => {
  return (
    <Modal
      okText={"Enviar email"}
      cancelText={"Voltar"}
      footer={null}
      title={"Recuperar senha"}
      visible={visible}
      onCancel={() => toggleForgotPassModal(false)}
    >
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          touched,
          isSubmitting,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-label">Email</div>
            <Form.Item
              hasFeedback
              className="common--margin-bottom20"
              validateStatus={touched.email && errors.email ? "error" : ""}
              help={touched.email && errors.email}
            >
              <Input
                className="form-input"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange("email")}
                tabIndex={1}
                size="large"
              />
            </Form.Item>
            <Row justify={"end"}>
              <Button
                type="primary"
                style={{ marginRight: "10px" }}
                onClick={() => toggleForgotPassModal(false)}
              >
                Voltar
              </Button>
              <Button
                disabled={!validationSchema.isValidSync(values) || isSubmitting}
                htmlType="submit"
                title="Clique aqui para recuperar a senha"
                type={isSubmitting ? "default" : "primary"}
                className="common--submit-button"
              >
                Recuperar senha
              </Button>
            </Row>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
