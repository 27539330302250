/**
 * Ajuste decimal recursivo de um número.
 *
 * @param	{Number}	value	O número a arredondar.
 * @param	{Integer}	exp		O expoente (o logaritmo decimal da base pretendida).
 * @returns	{Number}			O valor depois de ajustado.
 */
function round10(value: any, exp: number) {
  value = Number(value);
  exp = Number(exp);
  // Se o valor não é um número ou o exp não é inteiro...
  if (Number.isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return null;
  }

  // Transformando para string
  value = value.toString().split("e");

  value = Math.round(
    Number(`${value[0]}e${value[1] ? Number(value[1]) - exp : -exp}`),
  );
  // Transformando de volta
  value = value.toString().split("e");
  return Number(`${value[0]}e${value[1] ? Number(value[1]) + exp : exp}`);
}

/**
 * Ajuste decimal de um número.
 *
 * @param	{Number}	value	O número a arredondar.
 * @param	{Integer}	exp		O expoente (o logaritmo decimal da base pretendida).
 * @param	{Integer}	decimalCheckLimit	  Limite (número de casas para checar)
 * @returns	{Number}			O valor depois de ajustado.
 */
export function decimalAdjust(value: any, exp: any, decimalCheckLimit = -1) {
  value = Number(value);
  exp = Number(exp);
  // Se o valor não é um número ou o exp não é inteiro...
  if (Number.isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return null;
  }

  const [, decimal] = value.toString().split(".");
  if (Number(decimal?.[Math.abs(exp)]) < decimalCheckLimit) {
    return value;
  }
  // Transformando para string

  let i = 1;
  while (i + Math.abs(exp) <= decimal?.length) {
    value = round10(value, -decimal.length + i);
    i++;
  }

  return value;
}
