import { axiosInstance } from "../../common/app";
import { APP_API_ROOT } from "../../common/constants";
import { SESSION_API_ROOT } from "../../common/constants";

class LoginService {
  async auth(values: any) {
    return axiosInstance.post(`${APP_API_ROOT}/tutor/auth`, values);
  }

  async logout(personType: string) {
    return axiosInstance.post(`${APP_API_ROOT}/${personType}/logout`, null);
  }

  async verifySession() {
    return axiosInstance.post(`${SESSION_API_ROOT}/session`, null);
  }

  async authPortal(values: any) {
    const loginApi = await axiosInstance.post(
      `${APP_API_ROOT}/aluno/auth`,
      values,
    );
    return loginApi;
  }
}

export default new LoginService();
