import React, { useState, useEffect, useContext } from "react";
import $ from "jquery";
import {
  Row,
  Col,
  Select,
  Input,
  Button,
  Upload,
  message,
  Popover,
  Tooltip,
  Checkbox,
} from "antd";
import { ChromePicker } from "react-color";
import Grid from "antd/lib/card/Grid";
import { steps } from "./steps";
import { GameContext } from "../..";
import { compressImage } from "../utils/compress-image";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { IntroJS } from "../utils/tutorial";
import { ModalActions } from "./modal-actions";
import { v4 as uuidV4 } from "uuid";
import { addSound, showFigure } from "./utils";
import "./styles.less";
import { LIMIT_SHAPES } from "../game/classes/Stage";
import { If } from "react-extras";
import { PAINEL_ACTIONS_SCRIPTS, SCRIPT_NAMES } from "utils/enums";

const generateRow = ({ children, id, title }: any) => {
  return (
    <Row id={id} className="rows-painel-attrs">
      <Col span={8}>{title}</Col>
      <Col span={14}>{children}</Col>
    </Row>
  );
};

export const PainelAttrs = () => {
  const { game } = useContext(GameContext);
  const [initialSteps, setInitialSteps] = useState(
    !localStorage.getItem("steps-fig")
  );
  const [enableSteps, setEnabledSteps] = useState(false);
  const { gameState } = game;
  const { currentStage: stage } = gameState;
  const [shape, setShape] = useState(stage?.shapeInUse);
  const [visible, setVisible] = useState(false);
  const [script, setScript] = useState("");
  const [scriptSelecteds, setScriptSelecteds] = useState<any[]>([]);

  useEffect(() => {
    if (gameState.currentStage.shapeInUse?.id && initialSteps) {
      setEnabledSteps(true);
      setInitialSteps(false);
      localStorage.setItem("steps-fig", "true");
    }
  }, [gameState.currentStage.shapeInUse, initialSteps]);

  const assignScriptFigure = ({ actionElement, actionStage, action }) => {
    const {
      currentStage: { shapeInUse },
    } = gameState;
    const id = uuidV4();
    let errorMessage = "";
    const input = $("<input/>")
      .attr("type", "file")
      .attr("name", "file")
      .attr("id", "add-script");
    switch (Number(action)) {
      case 0:
        input.attr("accept", "image/*");
        input.trigger("click");
        input.on("change focus", async function () {
          const self: any = this;
          if (self.files?.length) {
            const fig = await showFigure(self);
            shapeInUse[script][id] = {
              type: "image",
              name: `Imagem ${self.files[0]?.name}`,
              value: fig,
            };
            game.updateCurrentStage(gameState.currentStage, gameState.stages);
          }
        });
        break;
      case PAINEL_ACTIONS_SCRIPTS.NEW_AUDIO:
        input.attr("accept", "audio/*");
        input.trigger("click");
        input.on("change", async function () {
          const self: any = this;
          if (self.files?.length) {
            const sound = await addSound(self.files[0]);
            shapeInUse[script][id] = {
              type: "sound",
              name: `Som - ${self.files[0].name}`,
              value: sound,
            };
            game.updateCurrentStage(gameState.currentStage, gameState.stages);
          }
        });
        break;
      case PAINEL_ACTIONS_SCRIPTS.PREVIOUS_STAGE:
        shapeInUse[script][id] = {
          type: "previous",
          name: "Vai para fase anterior",
          value: true,
        };
        break;
      case PAINEL_ACTIONS_SCRIPTS.NEXT_STAGE:
        shapeInUse[script][id] = {
          type: "next",
          name: "Vai para próxima fase",
          value: true,
        };
        break;
      case PAINEL_ACTIONS_SCRIPTS.SPECIFY_STAGE:
        if (actionStage) {
          shapeInUse[script][id] = {
            type: "specify",
            name: `Vai para fase - ${actionStage}`,
            value: actionStage,
          };
        } else errorMessage = "Por favor, selecione uma fase";
        break;
      case PAINEL_ACTIONS_SCRIPTS.END_GAME:
        shapeInUse[script][id] = {
          type: "end",
          name: "Terminar jogo",
          value: true,
        };
        break;
      case PAINEL_ACTIONS_SCRIPTS.SHOW_SHAPE:
        if (actionElement) {
          shapeInUse[script][id] = {
            type: "show",
            name: `Mostrar figura - ${actionElement}`,
            value: actionElement,
          };
        } else errorMessage = "Por favor, selecione uma figura";
        break;
      case PAINEL_ACTIONS_SCRIPTS.HIDE_SHAPE:
        if (actionElement) {
          shapeInUse[script][id] = {
            type: "hide",
            name: `Esconder figura - ${actionElement}`,
            value: actionElement,
          };
        } else errorMessage = "Por favor, selecione uma figura";
        break;
      case PAINEL_ACTIONS_SCRIPTS.AWAITING_TIME:
        if (actionElement) {
          shapeInUse[script][id] = {
            type: "sleep",
            name: `Aguardar tempo - ${actionElement}`,
            value: actionElement,
          };
        } else errorMessage = "Por favor, insira um valor";
        break;
      default:
        break;
    }

    if (errorMessage) {
      message.info(errorMessage);
    } else {
      game.updateCurrentStage(gameState.currentStage, gameState.stages);
      setVisible(false);
    }
  };

  const createRowActions = ({ title, step, stage, scriptName }) => {
    const shape = stage?.shapeInUse;
    const scriptToSearch = scriptSelecteds.find(
      (scriptSelected) => scriptSelected.key === scriptName
    );
    return (
      <Row id={step} style={{ marginTop: "10px" }}>
        <Col span={8} style={{ display: "flex" }}>
          <Row align="middle" justify="center">
            {title}
          </Row>
        </Col>
        <Col span={12}>
          <select
            multiple
            size={4}
            style={{ width: "100%", height: "100%" }}
            onClick={(event: any) => {
              const value = event?.target?.value;

              if (value) {
                setScriptSelecteds([{ key: scriptName, script: value }]);
              }
            }}
          >
            {stage?.shapeInUse &&
              shape[scriptName] &&
              Object.keys(shape[scriptName]).map((action) => (
                <option key={action} value={action}>
                  {" "}
                  {shape[scriptName][action].name}{" "}
                </option>
              ))}
          </select>
        </Col>
        <Col span={4}>
          <Row>
            <Button
              size="small"
              className="add-script"
              onClick={() => {
                setScript(scriptName);
                setVisible(true);
              }}
            >
              <i className="fa fa-plus" aria-hidden="true" />
            </Button>
          </Row>
          <Row>
            <Button
              size="small"
              className="delete-script"
              disabled={!scriptToSearch}
              onClick={() => {
                Reflect.deleteProperty(
                  shape[scriptToSearch.key],
                  scriptToSearch.script
                );
                setScriptSelecteds([]);
                game.updateCurrentStage(stage, gameState.stages);
              }}
            >
              <i className="fa fa-minus" aria-hidden="true" />
            </Button>
          </Row>
          {/* <Row>
            <Button
              size="small"
              className="view-script-detail"
            >
              <i className="fa fa-eye" aria-hidden="true" />
            </Button>
          </Row> */}
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (gameState.currentStage?.shapeInUse)
      setShape(gameState.currentStage?.shapeInUse);
  }, [gameState]);

  const onChangeProperty = (key, value, object = false, saveState = true) => {
    saveState && stage.shapeInUse.setData([{ info: key, value }]);
    if (object) object[key] = value;
    else stage.shapeInUse[key] = value;
    setShape((state) => {
      if (!object) state[key] = value;
      return { ...state };
    });
  };

  return (
    <Grid
      className="painelAttrs"
      style={{
        width: "100%",
        maxHeight: "720px",
        overflow: "scroll",
      }}
    >
      <Row
        align="middle"
        justify="center"
        style={{
          marginBottom: "15px",
          fontWeight: "bold",
        }}
      >
        Configurações do elemento
        <div
          id="step-fig31"
          className="tutorial-stage"
          onClick={() => setEnabledSteps(true)}
        >
          (Ver tutorial)
        </div>
      </Row>
      <IntroJS
        steps={steps}
        stepsEnabled={enableSteps}
        onExit={() => setEnabledSteps(false)}
      />
      {generateRow({
        id: "step-fig12",
        children: <Input disabled type="number" value={shape?.id} />,
        title: "Número do elemento",
      })}

      {generateRow({
        id: "step-fig13",
        children: (
          <Input
            type="number"
            value={shape?.zIndex}
            onChange={(event: any) =>
              onChangeProperty("zIndex", Number(event.target.value))
            }
          />
        ),
        title: "Profundidade",
      })}

      {generateRow({
        id: "step-fig14",
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.velocity}
            onChange={(value: any) =>
              onChangeProperty("velocity", Number(value))
            }
          >
            <Select.Option value={10}> Lenta </Select.Option>
            <Select.Option value={30}> Média </Select.Option>
            <Select.Option value={50}> Alta </Select.Option>
            <Select.Option value={5}> Teletransporte </Select.Option>
          </Select>
        ),
        title: "Velocidades",
      })}

      {generateRow({
        id: "step-fig15",
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.bordas ? 1 : 0}
            onChange={(value: any) => onChangeProperty("bordas", value === 1)}
          >
            <Select.Option value={1}> Sim </Select.Option>
            <Select.Option value={0}> Não </Select.Option>
          </Select>
        ),
        title: "Bordas?",
      })}

      {generateRow({
        id: "step-fig16",
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.clickable ? 1 : 0}
            onChange={(value: any) =>
              onChangeProperty("clickable", value === 1)
            }
          >
            <Select.Option value={1}> Sim </Select.Option>
            <Select.Option value={0}> Não </Select.Option>
          </Select>
        ),
        title: "Arrastável?",
      })}

      {generateRow({
        id: "step-fig17",
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.hidden ? 1 : 0}
            onChange={(value: any) => onChangeProperty("hidden", value === 1)}
          >
            <Select.Option value={1}> Sim </Select.Option>
            <Select.Option value={0}> Não </Select.Option>
          </Select>
        ),
        title: "Esconder?",
      })}

      {generateRow({
        id: "step-fig17",
        children: (
          <>
            <Select
              style={{ width: "100%" }}
              value={shape?.animated ? 1 : 0}
              onChange={(value: any) => {
                onChangeProperty("animated", value === 1);
                onChangeProperty("animatedHeight", value === 1);
                onChangeProperty("animatedWidth", value === 1);
              }}
            >
              <Select.Option value={1}> Sim </Select.Option>
              <Select.Option value={0}> Não </Select.Option>
            </Select>
            <If condition={shape?.animated}>
              <Row align="middle" justify="center">
                <Checkbox
                  checked={shape?.animatedHeight}
                  onClick={() =>
                    onChangeProperty("animatedHeight", !shape?.animatedHeight)
                  }
                >
                  Vertical
                </Checkbox>
                <Checkbox
                  checked={shape?.animatedWidth}
                  onClick={() =>
                    onChangeProperty("animatedWidth", !shape?.animatedWidth)
                  }
                >
                  Horizontal
                </Checkbox>
              </Row>
            </If>
          </>
        ),
        title: "Movimentar elemento?",
      })}

      {/* {generateRow({
        id: "step-fig17",
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.isBlockCollision ? 1 : 0}
            onChange={(value: any) =>
              onChangeProperty("isBlockCollision", value === 1)
            }
          >
            <Select.Option value={1}> Sim </Select.Option>
            <Select.Option value={0}> Não </Select.Option>
          </Select>
        ),
        title: "É um bloco de colisão?",
      })} */}

      {generateRow({
        id: "step-fig18",
        children: (
          <Input
            type="range"
            min={1}
            max={10}
            value={Number(shape?.opacity * 10)}
            onChange={(event: any) => {
              onChangeProperty("opacity", Number(event.target.value) / 10);
            }}
          />
        ),
        title: "Opacidade",
      })}

      {generateRow({
        id: "step-fig30",
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.hasShadow ? 1 : 0}
            onChange={(value: any) =>
              onChangeProperty("hasShadow", value === 1)
            }
          >
            <Select.Option value={1}> Sim </Select.Option>
            <Select.Option value={0}> Não </Select.Option>
          </Select>
        ),
        title: "Sombreamento?",
      })}

      <If condition={shape.hasShadow}>
        {generateRow({
          id: "step-fig18",
          children: (
            <Input
              type="range"
              min={0}
              max={10}
              value={Number(shape?.shadow)}
              onChange={(event: any) => {
                onChangeProperty("shadow", Number(event.target.value));
              }}
            />
          ),
          title: "Tamanho do sombreamento",
        })}
      </If>

      {generateRow({
        id: "step-fig19",
        children: (
          <ChromePicker
            color={shape?.backGroundColor}
            onChange={(color: any) =>
              onChangeProperty("backGroundColor", color.hex)
            }
          />
        ),
        title: "Cor",
      })}

      {generateRow({
        id: "step-fig20",
        children: (
          <Input
            type="number"
            value={shape?.x}
            onChange={(event: any) => {
              onChangeProperty("x", Number(event.target.value));
              stage.createCorners();
            }}
          />
        ),
        title: "Posição Horizontal",
      })}

      {generateRow({
        id: "step-fig21",
        children: (
          <Input
            type="number"
            value={shape?.y}
            onChange={(event: any) => {
              onChangeProperty("y", Number(event.target.value));
              stage.createCorners();
            }}
          />
        ),
        title: "Posição Vertical",
      })}

      {generateRow({
        id: "step-fig22",
        children: (
          <Input
            type="number"
            value={shape?.width}
            onChange={(event: any) => {
              onChangeProperty("width", Number(event.target.value));
              stage.createCorners();
            }}
          />
        ),
        title: "Largura",
      })}

      {generateRow({
        id: "step-fig23",
        children: (
          <Input
            type="number"
            value={shape?.height}
            onChange={(event: any) => {
              onChangeProperty("height", Number(event.target.value));
              stage.createCorners();
            }}
          />
        ),
        title: "Altura",
      })}
      {generateRow({
        id: "step-fig24",
        children: (
          <Select
            value={shape?.matchId}
            mode="tags"
            tokenSeparators={[","]}
            style={{ width: "100%" }}
            onChange={(value: any) => onChangeProperty("matchId", value)}
          >
            {stage?.shapes
              ?.filter(
                (shp: any) =>
                  shp.id !== shape?.id &&
                  shp.id !== LIMIT_SHAPES &&
                  !shape?.matchId?.includes(shp.id)
              )
              .map((shp: any) => (
                <Select.Option key={shp.id} value={shp.id?.toString()}>
                  {" "}
                  {shp.id}
                </Select.Option>
              ))}
          </Select>
        ),
        title: "Elementos compatíveis",
      })}

      {generateRow({
        id: "step-fig25",
        children: (
          <Select
            style={{ width: "100%" }}
            value={shape?.dificult}
            onChange={(value: any) => onChangeProperty("dificult", value)}
          >
            <Select.Option value={-1}> Sempre acertar </Select.Option>
            <Select.Option value={0}> Facilidade de acertar </Select.Option>
            <Select.Option value={1}>
              {" "}
              Média (metade do elemento sobreposto){" "}
            </Select.Option>
            <Select.Option value={2}>
              {" "}
              Alta (todo elemento sobreposto){" "}
            </Select.Option>
          </Select>
        ),
        title: "Dificuldade de acerto",
      })}

      {generateRow({
        id: "step-fig26",
        children: (
          <Row align="middle" justify="center" style={{ display: "flex" }}>
            <Input
              style={{ width: "54%" }}
              value={shape?.text?.value}
              onChange={(event: any) => {
                const { value } = event.target;
                stage.shapeInUse?.setData([{ info: "text" }]);
                const measureTextWidth = stage.getContext().measureText(value)
                  .width;
                const width =
                  value && measureTextWidth > shape.width
                    ? measureTextWidth
                    : shape.width;
                onChangeProperty("width", width, false, false);
                onChangeProperty("value", value, shape.text, false);
                stage.createCorners();
              }}
            />
            <Tooltip title="Tamanho do texto">
              <Input
                type="number"
                style={{ width: "29%", marginLeft: "5px" }}
                value={shape?.text?.tam}
                onChange={(event: any) => {
                  const { value } = event.target;
                  stage.shapeInUse.setData([{ info: "text" }]);
                  onChangeProperty("tam", value, shape?.text, false);
                  const tamHeight = Number.parseInt(value, 10) / 0.75;
                  const height =
                    value && tamHeight > shape.height
                      ? tamHeight
                      : shape.height;
                  onChangeProperty("height", height, false, false);
                  const measureTextWidth = stage
                    .getContext()
                    .measureText(shape?.text?.value).width;
                  const width =
                    value && measureTextWidth > shape.width
                      ? measureTextWidth
                      : shape.width;
                  onChangeProperty("width", width, false, false);
                  stage.createCorners();
                }}
              />
            </Tooltip>
            <Popover
              content={
                <ChromePicker
                  color={shape?.text?.fill}
                  onChange={(color: any) => {
                    stage.shapeInUse.setData([{ info: "text" }]);
                    onChangeProperty("fill", color.hex, shape?.text, false);
                  }}
                />
              }
              trigger="click"
              title="Cor"
            >
              <Tooltip title="Cor do texto">
                <Input
                  size="large"
                  type="button"
                  style={{
                    backgroundColor: shape?.text?.fill,
                    width: "10%",
                    height: "30px",
                    marginLeft: "5px",
                    border: "1px solid black",
                  }}
                />
              </Tooltip>
            </Popover>
          </Row>
        ),
        title: "Texto",
      })}

      {generateRow({
        id: "step-fig27",
        children: (
          <Row>
            <Col span={12}>
              <Upload
                listType="picture-card"
                showUploadList={false}
                customRequest={() => ""}
                accept="image/*"
                onChange={async (info) => {
                  try {
                    const image = await compressImage(info.file.originFileObj);
                    const oReader = new FileReader();
                    oReader.addEventListener("load", (event) =>
                      onChangeProperty("image64", event.target?.result)
                    );
                    oReader.readAsDataURL(image);
                  } catch {
                    message.error(
                      `Ocorreu um erro ai importar ${info.file.name}, tente novamente`
                    );
                  }
                }}
              >
                <div className="image-edit">
                  <PlusOutlined />
                  <div className="ant-upload-text">Imagem</div>
                </div>
              </Upload>
            </Col>
            {shape?.image64 && (
              <Col
                span={12}
                style={{
                  display: "grid",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "-21px",
                }}
              >
                <img src={shape?.image64} alt="shape" width="80" height="60" />
                <DeleteOutlined
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={() => onChangeProperty("image64", "")}
                />
              </Col>
            )}
          </Row>
        ),
        title: "Imagem",
      })}

      {createRowActions({
        title: "QuandoClicar?",
        step: "step28",
        scriptName: SCRIPT_NAMES.QUANDO_CLICAR,
        stage: gameState.currentStage,
      })}
      {createRowActions({
        title: "QuandoAcertar?",
        step: "step29",
        scriptName: SCRIPT_NAMES.QUANDO_ACERTAR,
        stage: gameState.currentStage,
      })}
      {createRowActions({
        title: "QuandoErrar?",
        step: "step30",
        scriptName: SCRIPT_NAMES.QUANDO_ERRAR,
        stage: gameState.currentStage,
      })}
      <ModalActions
        assignScript={assignScriptFigure}
        script={script}
        setVisible={setVisible}
        visible={visible}
      />
    </Grid>
  );
};
