import React, { useEffect, useCallback } from "react";
import { useGameState } from "./classes/Game";
import { Stage } from "./classes/Stage";
import { GameContext } from "../..";

export const Load = ({
  selectedGame,
  liveMode = false,
  isPlayTEA = false,
  children: Component,
  endGameOnFinish = false,
}) => {
  const game = useGameState({ selectedGameName: selectedGame?.nome });
  const initGame = useCallback(() => {
    if (!selectedGame?.game && !game?.gameState.stages?.length) {
      const stage = new Stage(document.querySelector("canvas"), 1);
      stage.setName("Fase principal");
      game?.gameState.setImage(selectedGame?.foto);
      game?.gameState.setCodigo(selectedGame?.game?.codigo);
      game.updateStages({ stages: [stage] });
      game.updateCurrentStage(stage);
      game?.gameState.setEvents(stage);
      stage.resizeResolution();
      if (liveMode) {
        stage.setLiveMode(true);
        stage.start(true, game.gameState);
      }
      return;
    }

    if (!game?.gameState.stages?.length) {
      const stages = game.create(selectedGame, isPlayTEA);

      game.updateStages({ stages });
      game?.gameState.setImage(
        Buffer.from(selectedGame.foto.data).toString("utf-8")
      );
      game.updateCurrentStage(stages[0]);
      game?.gameState.setEvents(stages[0]);
      game?.gameState.setCodigo(selectedGame.codigo);
      game.gameState.setEndGame(endGameOnFinish);
      stages[0].resizeResolution();
      if (liveMode) {
        stages[0].setLiveMode(true);
        stages[0].start(true, game.gameState);
      }
    }
  }, [selectedGame, game, endGameOnFinish, liveMode, isPlayTEA]);

  useEffect(() => {
    initGame();
  }, [initGame]);

  useEffect(() => {
    if (game?.gameState?.currentStage?.id)
      game?.gameState.currentStage.start(false, game?.gameState);
  }, [game]);

  return (
    <GameContext.Provider value={{ game }}>{Component}</GameContext.Provider>
  );
};
