import { combineReducers } from "redux";

import {
  LOGIN_STUDENT,
  LOGOUT_STUDENT,
  SAVE_LOCAL_STUDENT,
  CLEAN_STUDENT_STATE,
} from "./actions";

export const defaultPartnerState = {};

export function student(state = defaultPartnerState, action) {
  switch (action?.type) {
    case LOGIN_STUDENT: {
      const { payload } = action;
      return {
        ...state,
        isSaved: true,
        ...payload,
      };
    }

    case SAVE_LOCAL_STUDENT: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }

    case LOGOUT_STUDENT: {
      return {
        ...defaultPartnerState,
      };
    }

    case CLEAN_STUDENT_STATE: {
      return {
        ...state,
        codigo: null,
      };
    }

    default:
      return state;
  }
}

export const studentReducer = combineReducers({ student });
