export const CREATE_GAME = "[GAME] CREATE GAME";
export const UPDATE_GAME = "[GAME] UPDATE_GAME";
export const CREATE_SHAPE_FROM_PASTE = "[GAME] CREATE SHAPE FROM PASTE";
export const UPDATE_EYE_VISIBILITY = "[GAME] UPDATE_EYE_VISIBILITY";
export const END_GAME = "[GAME] END_GAME";
export const END_GAME_PLAY_TEA = "[GAME] END_GAME_PLAY_TEA";
export const RESET_GAME_STATE = "[GAME] END_GAME_PLAY_TEA";
export const TEST_GAME = "[GAME] TEST_GAME";
export const SWIPE_LEFT_ACTION = "[GAME] SWIPE_LEFT_ACTION";
export const SWIPE_RIGHT_ACTION = "[GAME] SWIPE_RIGHT_ACTION";
export const CLEAR_SWIPES = "[GAME] CLEAR_SWIPES";
