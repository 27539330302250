import { axiosInstance } from "../common/app";
import { APP_API_ROOT } from "../common/constants";

class MentoringService {
  async students(tutor: number) {
    return axiosInstance.get(`${APP_API_ROOT}/tutor/${tutor}/alunos`);
  }

  async studentsStatistics(tutor: number) {
    return axiosInstance.get(
      `${APP_API_ROOT}/tutor/${tutor}/alunos/estatisticas`,
    );
  }

  async save(management: any) {
    return axiosInstance.post(`${APP_API_ROOT}/tutor`, management);
  }

  async resetPassword(data: any) {
    return axiosInstance.put(`${APP_API_ROOT}/tutor/auth/reset-password`, data);
  }

  async forgotPassword(email) {
    return axiosInstance.post(`${APP_API_ROOT}/tutor/auth/forgot-password`, {
      email,
    });
  }
}

export default new MentoringService();
