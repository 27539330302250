import { Shape } from "./Shape";
import { Mouse } from "./Mouse";
import { sleep } from "./utils";
import _ from "lodash";
import $ from "jquery";
import moment from "moment";
import { SCRIPT_NAMES } from "utils/enums";

let movingCorner = -1;
let canvas;
let touchtime;
let delayClick = 500;

const playTEACreatorDimensions = {
  width: 1280,
  height: 720,
};

export const LIMIT_SHAPES = 1000000;
export class Stage {
  constructor(
    canvasDoc,
    id,
    ids = [],
    shapes = [],
    quandoIniciar = {},
    quandoTerminar = {},
    acertosDoCenario = 0,
    isPlayTEA,
  ) {
    this.clock = 0;
    this.id = id;
    this.mouse = new Mouse();
    canvas = canvasDoc;
    this.context = canvas.getContext("2d");
    this.updateSizeCanvas(isPlayTEA);
    this.selectedGroupShapes = new Shape({
      hidden: true,
      clickable: false,
      visible: false,
      id: LIMIT_SHAPES,
      zIndex: -1000000,
    });
    this.selectedShapes = [];
    this.shapes = [...shapes, this.selectedGroupShapes];
    this.ids = ids;
    this.name = "";
    this.fps = 40;
    this.looping = null;
    this.initialMovement = null;
    this.currentStage = false;
    this.executingScript = false;
    this.dragging = false;
    this.shapeSelected = -1;
    this.shapeInUse = null;
    this.dragoffx = 0;
    this.init = true;
    this.acertosExecutados = 0;
    this.acertosDoCenario = acertosDoCenario;
    this.dragoffy = 0;
    this.quandoIniciar = quandoIniciar;
    this.quandoTerminar = quandoTerminar;
    this.clone = null;
    this.stageLiveMode = null;
    this.shapesDeleteds = [];
    this.corners = {
      shapes: [],
      father: -1,
    };
    this.liveMode = false;
    this.actions = [];
  }

  // Draws the selection rectangle
  drawSelectionRectangle = ({
    selectionStartX,
    selectionStartY,
    selectionEndX,
    selectionEndY,
  }) => {
    // Figure out the top left corner of the rectangle
    const x = Math.min(selectionStartX, selectionEndX);
    const y = Math.min(selectionStartY, selectionEndY);

    // Calculate the width and height of the rectangle
    const width = Math.abs(selectionEndX - selectionStartX);
    const height = Math.abs(selectionEndY - selectionStartY);

    // Draw the rectangle
    this.selectedGroupShapes.width = width;
    this.selectedGroupShapes.height = height;
    this.selectedGroupShapes.x = x;
    this.selectedGroupShapes.y = y;
    this.selectedGroupShapes.visible = true;
  };

  setName(name) {
    this.name = name;
  }

  setLiveMode(flag) {
    this.liveMode = flag;
    if (this.liveMode) {
      this.corners.shapes = [];
      this.corners.father = -1;
    }
  }

  setInit(flag) {
    this.init = flag;
  }

  getWidth() {
    return this.width;
  }

  getHeight() {
    return this.height;
  }

  getContext() {
    return this.context;
  }

  getCanvas() {
    return canvas;
  }

  updateSizeCanvas(isPlayTEA) {
    this.width = isPlayTEA
      ? $(window).width() - 25
      : playTEACreatorDimensions.width;
    this.height = isPlayTEA
      ? $(window).height() - 25
      : playTEACreatorDimensions.height;
    canvas.width = this.width;
    canvas.height = this.height;
    this.canvas = canvas;

    this.ghostCanvas = document.createElement("canvas");
    this.ghostCanvas.height = this.height;
    this.ghostCanvas.width = this.width;
    this.ghostctx = this.ghostCanvas.getContext("2d");
  }

  updateSizeShapes(padx, pady, alt_disp, larg_disp) {
    this.shapes.forEach((shape) =>
      shape.resize(
        playTEACreatorDimensions.height,
        playTEACreatorDimensions.width,
        alt_disp,
        larg_disp,
        padx,
        pady,
      ),
    );
    if (this.shapeInUse?.id) this.createCorners();
  }

  resizeResolution() {
    const width_creator = playTEACreatorDimensions.width;
    const height_creator = playTEACreatorDimensions.height;
    let larg_disp = this.width;
    let alt_disp = this.height;
    let padx = 0,
      alt_mod = 0,
      larg_mod = 0,
      pady = 0;

    if (larg_disp < alt_disp) {
      alt_mod = larg_disp / (width_creator / height_creator);
      pady = (alt_disp - alt_mod) / 2;
      alt_disp = alt_mod;
    } else {
      larg_mod = (width_creator / height_creator) * alt_disp;
      padx = (larg_disp - larg_mod) / 2;
      larg_disp = larg_mod;
    }

    if (!this.resized) {
      this.updateSizeShapes(padx, pady, alt_disp, larg_disp);
      this.resized = true;
    }
  }

  getShapeSelected() {
    return this.shapeInUse || {};
  }

  clearSelectedGroupShapes() {
    this.selectedGroupShapes.visible = false;
    this.selectedGroupShapes.width = 0;
    this.selectedGroupShapes.height = 0;
    this.selectedGroupShapes.x = 0;
    this.selectedGroupShapes.y = 0;
  }

  clickAction(e, callbackDlbClick) {
    this.clear(this.ghostctx);

    if (!touchtime) {
      touchtime = moment();
    } else {
      if (moment().isSameOrBefore(touchtime.add(delayClick, "milliseconds"))) {
        touchtime = null;
        callbackDlbClick && callbackDlbClick();
        return;
      } else {
        touchtime = null;
      }
    }
    if (this.currentStage && this.init) {
      let mouse = this.mouse.getPosition(e, canvas);
      if (this.selectedGroupShapes.visible) {
        this.selectedGroupShapes.draw(this.ghostctx);
        const imageData = this.ghostctx.getImageData(mouse.x, mouse.y, 1, 1);
        if (imageData.data[3] > 0) {
          this.dragoffx = mouse.x - this.selectedGroupShapes.x;
          this.dragoffy = mouse.y - this.selectedGroupShapes.y;
          this.selectedGroupShapes.posInitialX = this.selectedGroupShapes.x;
          this.selectedGroupShapes.posInitialY = this.selectedGroupShapes.y;
          this.shapeSelected = this.shapes.findIndex(
            (shape) => shape.id === this.selectedGroupShapes.id,
          );
          this.selectedShapes.map((shape) => {
            shape.dragoffx = mouse.x - shape.x;
            shape.dragoffy = mouse.y - shape.y;
            return shape;
          });
        } else {
          this.clearSelectedGroupShapes();
          this.startMovement = _.cloneDeep(mouse);
        }
      } else {
        this.startMovement = _.cloneDeep(mouse);
        for (let i = this.shapes.length - 1; i >= 0; i--) {
          this.shapes[i].draw(this.ghostctx);
          const imageData = this.ghostctx.getImageData(mouse.x, mouse.y, 1, 1);
          if (imageData.data[3] > 0) {
            this.shapeSelected = i;
            this.shapeInUse = this.shapes[this.shapeSelected];
            this.dragoffx = mouse.x - this.shapeInUse.x;
            this.dragoffy = mouse.y - this.shapeInUse.y;
            this.shapeInUse.posInitialX = this.shapeInUse.x;
            this.shapeInUse.posInitialY = this.shapeInUse.y;
            if (!this.liveMode) this.createCorners();
            break;
          }
          this.shapeSelected = -1;
          this.shapeInUse = null;
        }
      }

      if (this.shapeInUse?.id) {
        for (let corner = 0; corner < this.corners.shapes.length; corner++) {
          if (this.corners.shapes[corner].contains(mouse.x, mouse.y)) {
            movingCorner = corner;
            this.styleCanvasCorners(corner);
            break;
          }
          canvas.style.cursor = "default";
        }
      }

      if (this.shapeSelected !== -1) {
        this.clear(this.ghostctx);
        this.dragging = true;
        return;
      } else {
        this.shapeSelected = -1;
        movingCorner = -1;
        this.shapeInUse = {};
        this.corners.shapes = [];
        this.corners.father = -1;
        return;
      }
    }
  }

  async playScriptTerminar(_, gameState = {}) {
    gameState.executeScriptTerminated();
  }

  async playScript({
    type,
    script,
    shapeSelected = null,
    gameState,
    init = true,
    name,
  }) {
    if (gameState) {
      gameState.executeScriptInitiate({
        type,
        script,
        shapeSelected,
        init,
        name,
      });
    }
  }

  createCorners(father = -1) {
    const defaultOptions = {
      height: 13,
      width: 13,
      zIndex: 9999999,
      clickable: true,
      visible: true,
      velocity: 0,
      backGroundColor: "red",
    };

    const half = defaultOptions.width / 2;
    this.corners.shapes = [
      new Shape({
        ...defaultOptions,
        x: this.shapeInUse.x - half,
        y: this.shapeInUse.y - half,
      }),
      new Shape({
        ...defaultOptions,
        x: this.shapeInUse.x + this.shapeInUse.width / 2 - half,
        y: this.shapeInUse.y - half,
      }),
      new Shape({
        ...defaultOptions,
        x: this.shapeInUse.x + this.shapeInUse.width - half,
        y: this.shapeInUse.y - half,
      }),
      new Shape({
        ...defaultOptions,
        x: this.shapeInUse.x - half,
        y: this.shapeInUse.y + this.shapeInUse.height / 2 - half,
      }),
      new Shape({
        ...defaultOptions,
        x: this.shapeInUse.x + this.shapeInUse.width - half,
        y: this.shapeInUse.y + this.shapeInUse.height / 2 - half,
      }),
      new Shape({
        ...defaultOptions,
        x: this.shapeInUse.x - half,
        y: this.shapeInUse.y + this.shapeInUse.height - half,
      }),
      new Shape({
        ...defaultOptions,
        x: this.shapeInUse.x + this.shapeInUse.width / 2 - half,
        y: this.shapeInUse.y + this.shapeInUse.height - half,
      }),
      new Shape({
        ...defaultOptions,
        x: this.shapeInUse.x + this.shapeInUse.width - half,
        y: this.shapeInUse.y + this.shapeInUse.height - half,
      }),
    ];
    this.corners.father = father !== -1 ? father : this.shapeSelected;
  }

  addShape(shapes = []) {
    this.shapes = this.shapes.concat(shapes);
  }

  getLastIdShape() {
    return (
      this.shapes
        ?.filter((shape) => shape.id !== LIMIT_SHAPES)
        ?.sort((a, b) => b.id - a.id)[0]?.id || 0
    );
  }

  clear(ctx) {
    if (ctx) return ctx.clearRect(0, 0, this.width, this.height);

    this.getContext().clearRect(0, 0, this.width, this.height);
    this.ghostctx.clearRect(0, 0, this.width, this.height);
    this.draw();
  }

  start(force = false, gameState) {
    if (!this.looping || force) {
      this.currentStage = true;
      let quandoIniciarScrpt = Object.keys(this.quandoIniciar);
      if (quandoIniciarScrpt.length && this.liveMode) {
        this.playScript({
          type: quandoIniciarScrpt,
          script: this.quandoIniciar,
          name: "quandoIniciar",
          gameState,
        });
      }
      this.clock = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
      this.looping = setInterval(() => {
        sleep(0.01).finally(() => {
          this.draw();
        });
      }, 1000 / this.fps);
    }
  }

  pauseScene() {
    this.currentStage = false;
    this.liveMode = false;
    this.clear();
  }

  stopRenderCanvas() {
    clearInterval(this.looping);
  }

  draw() {
    let shapeSorted = this.shapes.sort((a, b) => a.zIndex - b.zIndex);
    const rootContext = this.getContext();
    const auxContext = this.ghostctx;
    rootContext.clearRect(0, 0, this.width, this.height);
    auxContext.clearRect(0, 0, this.width, this.height);
    shapeSorted.forEach((shape) => {
      if (shape.animated && this.liveMode) {
        shape.animating(rootContext, false, [
          { width: canvas.width, height: canvas.height },
        ]);
      } else {
        shape.draw(rootContext);
        shape.draw(auxContext, true);
      }
    });

    if (this.corners.shapes.length > 0) {
      this.corners.shapes.forEach((shape) => {
        shape.draw(rootContext);
        shape.draw(auxContext, true);
      });
    }
  }

  styleCanvasCorners(corner) {
    switch (corner) {
      case 0:
        canvas.style.cursor = "nw-resize";
        break;
      case 1:
        canvas.style.cursor = "n-resize";
        break;
      case 2:
        canvas.style.cursor = "ne-resize";
        break;
      case 3:
        canvas.style.cursor = "w-resize";
        break;
      case 4:
        canvas.style.cursor = "e-resize";
        break;
      case 5:
        canvas.style.cursor = "sw-resize";
        break;
      case 6:
        canvas.style.cursor = "s-resize";
        break;
      case 7:
        canvas.style.cursor = "se-resize";
        break;
      default:
        break;
    }
  }

  moveAction(e) {
    touchtime = null;
    if (this.currentStage && this.init) {
      const mouse = this.mouse.getPosition(e, canvas);
      this.middleMovement = _.cloneDeep(mouse);
      if (this.startMovement && !this.shapeInUse?.id && !this.liveMode) {
        this.drawSelectionRectangle({
          selectionEndX: this.middleMovement?.x,
          selectionEndY: this.middleMovement?.y,
          selectionStartX: this.startMovement?.x,
          selectionStartY: this.startMovement?.y,
        });
      }
      for (let corner = 0; corner < this.corners.shapes.length; corner++) {
        if (this.corners.shapes[corner].contains(mouse.x, mouse.y)) {
          this.styleCanvasCorners(corner);
          break;
        }
        canvas.style.cursor = "default";
      }

      if (
        this.selectedGroupShapes.visible &&
        this.selectedShapes.length &&
        this.dragging
      ) {
        this.shapeInUse.x = mouse.x - this.dragoffx;
        this.shapeInUse.y = mouse.y - this.dragoffy;
        this.selectedShapes.map((shape) => {
          shape.x = mouse.x - shape.dragoffx;
          shape.y = mouse.y - shape.dragoffy;
          return shape;
        });
        this.openConfigs = false;
        return;
      }

      if (movingCorner >= 0) {
        return this.loadCorners(mouse, movingCorner);
      }

      if (
        (this.dragging &&
          this.shapeInUse &&
          this.shapeInUse.clickable &&
          !this.shapeInUse.used) ||
        (this.dragging && !this.liveMode)
      ) {
        this.shapeInUse.x = mouse.x - this.dragoffx;
        this.shapeInUse.y = mouse.y - this.dragoffy;
        this.openConfigs = false;
        if (!this.liveMode) this.createCorners();
      }
    }
  }

  /**
   * Load corners with movement
   * @param {Mouse} mouse - Positions X e Y
   */
  loadCorners(mouse, corner, shape) {
    if (this.currentStage && this.shapeInUse?.id) {
      if (corner >= 0) {
        const myShape = shape || this.shapes[this.corners.father];
        this.oldX = myShape.x;
        this.oldY = myShape.y;
        const mx = mouse.x - (shape?.dragoffx ?? 0);
        const my = mouse.y - (shape?.dragoffy ?? 0);
        switch (corner) {
          case 0:
            myShape.x = mx;
            myShape.y = my;
            myShape.width += this.oldX - mx;
            myShape.height += this.oldY - my;
            break;
          case 1:
            myShape.y = my;
            myShape.height += this.oldY - my;
            break;
          case 2:
            myShape.y = my;
            myShape.width = mx - this.oldX;
            myShape.height += this.oldY - my;
            break;
          case 3:
            myShape.x = mx;
            myShape.width += this.oldX - mx;
            break;
          case 4:
            myShape.width = mx - this.oldX;
            break;
          case 5:
            myShape.x = mx;
            myShape.width += this.oldX - mx;
            myShape.height = my - this.oldY;
            break;
          case 6:
            myShape.height = my - this.oldY;
            break;
          case 7:
            myShape.width = mx - this.oldX;
            myShape.height = my - this.oldY;
            break;
          default:
            return;
        }
        this.createCorners(this.corners.father);
      }
    } else {
      this.corners.shapes = [];
      this.corners.father = -1;
    }
  }

  /**
   *
   * @param {Shape} shape
   * @param {Shape} clone
   * @param {Number} collision - -1 - always, 0 - low, 1 - middle, 2 - high (using 10% off size);
   */
  collision(shape, clone, collision = 2) {
    if (collision === -1) return true;
    let catX = shape.centerX() - clone.centerX();
    let catY = shape.centerY() - clone.centerY();
    let halfWidth = shape.halfWidth() + clone.halfWidth();
    let halfHeight = shape.halfHeight() + clone.halfHeight();
    if (collision === 1) {
      return (
        Math.abs(catX) + shape.halfWidth() < halfWidth &&
        Math.abs(catY) + shape.halfHeight() < halfHeight
      );
    } else if (collision === 2) {
      return (
        Math.abs(catX) + shape.width - (shape.width * 10) / 100 <= halfWidth &&
        Math.abs(catY) + shape.height - (shape.height * 10) / 100 <= halfHeight
      );
    } else return Math.abs(catX) < halfWidth && Math.abs(catY) < halfHeight;
  }

  verifyShapesInSelected() {
    this.selectedShapes = [];
    for (const shape of this.shapes.filter(
      (shape) => shape.id < LIMIT_SHAPES,
    )) {
      if (
        this.collision(this.selectedGroupShapes, shape, 1) ||
        this.collision(shape, this.selectedGroupShapes, 1)
      ) {
        this.selectedShapes.push(shape);
      }
    }

    if (!this.selectedShapes.length) {
      this.selectedGroupShapes.visible = false;
    } else {
      this.shapeInUse = this.selectedGroupShapes;
    }
    return true;
  }

  checkQuandoAcertar({ clone, shapeInUse, gameState }) {
    this.shapes = this.shapes.filter(
      (shape) => shape.id !== clone.id && shape.id !== shapeInUse.id,
    );
    clone.used = true;
    const newShape = new Shape({
      ...shapeInUse,
      base64Image: shapeInUse.image64,
      used: true,
      x: clone.x,
      y: clone.y,
      velocity: clone.velocity,
      width: clone.width,
      height: clone.height,
      matchId: clone.matchId,
      clickable: clone.clickable,
      quandoClicar: clone.quandoClicar,
      quandoAcertar: clone.quandoAcertar,
      quandoErrar: clone.quandoErrar,
      animated: false,
    });
    this.acertosExecutados++;
    let quandoAcertar = Object.keys(shapeInUse.quandoAcertar);
    this.shapes.push(newShape);
    if (quandoAcertar.length) {
      this.playScript({
        type: quandoAcertar,
        name: "quandoAcertar",
        script: shapeInUse.quandoAcertar,
        shapeSelected: shapeInUse,
        gameState,
      });
    }
    this.shapeInUse = null;
    if (this.acertosExecutados === Number(this.acertosDoCenario)) {
      this.acertosExecutados = 0;
      this.init = false;
      this.playScriptTerminar(null, gameState);
    }
  }

  async dropAction(e, gameState) {
    let { shapeInUse } = this;
    this.oldY = null;
    this.oldX = null;
    this.startMovement = null;
    this.verifyShapesInSelected();
    movingCorner = -1;
    if (
      shapeInUse?.id &&
      (shapeInUse.x !== shapeInUse.posInitialX ||
        shapeInUse.y !== shapeInUse.posInitialY)
    ) {
      shapeInUse.normalizeSize();
      shapeInUse.setData([
        {
          info: "x",
          auxInfo: "posInitialX",
        },
        {
          info: "y",
          auxInfo: "posInitialY",
        },
      ]);
    }
    if (
      this.liveMode &&
      this.shapeInUse?.clickable &&
      this.currentStage &&
      this.init
    ) {
      if (shapeInUse.x === shapeInUse.posInitialX && this.liveMode) {
        let quandoClicar = Object.keys(shapeInUse.quandoClicar);
        if (quandoClicar.length) {
          this.playScript({
            type: quandoClicar,
            script: this.shapeInUse.quandoClicar,
            name: SCRIPT_NAMES.QUANDO_CLICAR,
            shapeSelected: shapeInUse,
            gameState,
          });
        }
      }

      if (shapeInUse.matchId.length) {
        for (let i = 0; i < shapeInUse.matchId.length; i++) {
          let match = shapeInUse.matchId[i];
          let clone = this.shapes.find((e) => e.id === Number(match));
          if (clone && !clone.used) {
            if (
              this.collision(shapeInUse, clone, shapeInUse.dificult) ||
              this.collision(clone, shapeInUse, shapeInUse.dificult)
            ) {
              this.checkQuandoAcertar({ clone, shapeInUse, gameState });
              break;
            }
          }
          if (i === this.shapeInUse.matchId.length - 1) {
            _errorStep({
              gameState,
              shapeSelected: shapeInUse,
              playScript: this.playScript,
            });
          }
        }
      } else {
        _errorStep({
          shapeSelected: shapeInUse,
          gameState,
          playScript: this.playScript,
        });
      }
    }
    this.dragging = false;
  }
}

const _errorStep = ({ shapeSelected, gameState, playScript }) => {
  let angle = Math.atan2(
    shapeSelected.posInitialY - shapeSelected.y,
    shapeSelected.posInitialX - shapeSelected.x,
  );
  if (shapeSelected.velocity !== 5) {
    shapeSelected.velocityX = shapeSelected.velocity * Math.cos(angle);
    shapeSelected.velocityY = shapeSelected.velocity * Math.sin(angle);
  } else {
    shapeSelected.x = shapeSelected.posInitialX;
    shapeSelected.y = shapeSelected.posInitialY;
  }
  let quandoErrar = Object.keys(shapeSelected.quandoErrar);
  if (quandoErrar.length)
    playScript({
      type: quandoErrar,
      script: shapeSelected.quandoErrar,
      name: "quandoErrar",
      shapeSelected,
      gameState,
    });
};
