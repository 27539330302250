import { Stage } from "./Stage";
import { Shape } from "./Shape";

export const startIntroFigura = () => {
  return "OK";
};

export const sleep = (timer) => {
  return new Promise((resolve, _) => {
    setTimeout(() => resolve(timer), timer);
  });
};

export const promiseSequentially = async (
  promises: any[],
  data = [],
  countPromises = 0,
) => {
  if (promises?.length > 0) {
    const result = await promises[countPromises]();
    countPromises++;
    if (countPromises < promises.length) {
      await promiseSequentially(promises, data.concat(result), countPromises);
    } else {
      return data;
    }
  }
};

export const getNewShapeSize = (width) => {
  if (width < 300) return { width: 30, height: 30 };
  if (width < 500) return { width: 50, height: 50 };
  if (width < 700) return { width: 80, height: 80 };

  return { width: 100, height: 100 };
};

export const cloneStage = (
  clone: any,
  nameScene: any,
  id: any,
  textInput: any,
  game: any,
) => {
  nameScene = textInput.val()
    ? textInput.val()
    : (clone.name ? clone.name : "Fase Principal") + " " + id;
  let stage = new Stage(
    {},
    id,
    clone.ids,
    [],
    clone.quandoIniciar,
    clone.quandoTerminar,
    clone.acertosDoCenario,
  );
  clone.shapes.forEach((shape: any) =>
    stage.shapes.push(new Shape({ ...shape })),
  );
  stage.setName(nameScene);
  game.currentStage.pauseScene();
  stage.start();
  game.setCurrentStage(stage);
  game.includeStage(stage);
  return { stage, nameScene };
};

export const loadAudio = (som) => {
  return new Promise((resolve, reject) => {
    const audio = new Audio();
    audio.src = som;
    audio.controls = false;
    audio.load();
    audio
      .play()
      .then(() => {})
      .catch(() => resolve(true));
    audio.onended = () => resolve(true);
  });
};
