export const steps = [
  {
    element: "#introFig",
    intro: `Este tutorial ajudará você a entender os conceitos sobre a propriedade dos elementos.`,
  },
  {
    element: "#step-fig12",
    intro: `Onde fica o identificador da figura, é utilizado para quando deseja ligar uma figura à esta.`,
  },
  {
    element: "#step-fig13",
    intro:
      "A profundidade é usada para colocar figuras à frente, ou atrás de outras.",
  },
  {
    element: "#step-fig14",
    intro:
      "Velocidade do elemento, quando errar ao arrastar o elemento pela tela.",
    position: "left",
  },
  {
    element: "#step-fig15",
    intro: "Adiciona/Remover bordas ao elemento.",
  },
  {
    element: "#step-fig16",
    intro:
      "Habilitar/Desabilitar a funcionalidade de arrastar o elemento quando jogar.",
  },
  {
    element: "#step-fig17",
    intro: "Esconde o elemento na tela",
  },
  {
    element: "#step-fig18",
    intro: "Aumentar/Diminuir a opacidade do elemento selecionado.",
  },
  {
    element: "#step-fig19",
    intro: "Adicione uma cor a um elemento.",
    position: "top",
  },
  {
    element: "#step-fig20",
    intro: "Refere-se a posição na vertical do elemento.",
  },
  {
    element: "#step-fig21",
    intro: "Refere-se a posição na horizontal do elemento.",
  },
  {
    element: "#step-fig22",
    intro: "Largura do elemento na tela.",
  },
  {
    element: "#step-fig23",
    intro: "Altura do elemento na tela.",
  },
  {
    element: "#step-fig24",
    intro:
      "Onde é configurado quais elementos farão 'match' com a figura selecionada (irá aparecer o id de cada elemento)",
  },
  {
    element: "#step-fig25",
    intro:
      "Dificuldade que a elemento terá para acertar o seu par quando for arrastada.",
    position: "top",
  },
  {
    element: "#step-fig26",
    intro: "Inserir/Modificar textos ao elemento.",
  },
  {
    element: "#step-fig27",
    intro: "Inserir fotos ao elemento",
  },
  {
    element: "#step-fig31",
    intro:
      "Caso precise rever os conceitos das propriedades dos elementos, basta clicar em um elemento e este botão irá aparecer.",
  },
];
