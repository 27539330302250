export enum CONTEXTS {
  PLAYTEA = "/playtea",
  PLAYTEA_CREATOR = "/playtea-creator",
}


export enum PAINEL_ACTIONS_SCRIPTS {
  NEW_AUDIO = 1,
  PREVIOUS_STAGE = 2,
  NEXT_STAGE = 3,
  SPECIFY_STAGE = 4,
  END_GAME = 5,
  SHOW_SHAPE = 6,
  HIDE_SHAPE = 7,
  AWAITING_TIME = 8
}

export enum PAINEL_ACTIONS_SCRIPTS_NAME {
  NEW_IMAGE = 'image',
  NEW_AUDIO = 'sound',
  PREVIOUS_STAGE = 'previous',
  NEXT_STAGE = 'next',
  SPECIFY_STAGE = 'specify',
  END_GAME = 'end',
  SHOW_SHAPE = 'show',
  HIDE_SHAPE = 'hide',
  AWAITING_TIME = 'sleep'
}

export enum SCRIPT_NAMES {
  QUANDO_INICIAR = 'quandoIniciar',
  QUANDO_TERMINAR = 'quandoTerminar',
  QUANDO_ACERTAR = 'quandoAcertar',
  QUANDO_ERRAR = 'quandoErrar',
  QUANDO_CLICAR = 'quandoClicar'
}