export enum PostMessagesTypes {
  ScreenOrientation = "screenOrientation",
  BackHandler = "backhandler",
  SwipeRight = "swipeRight",
  SwipeLeft = "swipeLeft",
}

export enum ScreenOrientation {
  PORTRAIT = 2,
  LANDSCAPE = 5,
}
