import React, { FC, ReactElement } from "react";
import { Layout } from "components/layout";
import { useHistory } from "react-router-dom";
import { Card, Modal } from "antd";
import { isMobileOnly } from "react-device-detect";
import { useScreenOrientation } from "hooks/useScreenOrientation";
import { ScreenOrientation } from "enums";
import { CONTEXTS } from "utils/enums";
import { useEffect } from "react";

const styles = {
  mobileCard: {
    marginBottom: "30px",
    marginLeft: "16px",
    marginRight: "17px",
  },
  mobileDiv: {
    display: "block",
  },
  desktopCard: {
    width: 400,
    marginRight: "20px",
    textAlign: "center",
  },
  deskTopDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
  },
};

const description = (
  <>
    <p>
      PlayTEA é uma plataforma didática cujo seu público alvo são crianças,
      jovens e adultos que se enquadram do transtorno do espectro autista.
    </p>
    <p>
      As atividades a serem realizadas são personalizáveis para que o usuário
      consiga realizar atividades de pareamento.
    </p>
    <p>
      Não limita-se apenas aos protetores do espectro, podendo ser utilizada em
      escolas regulares e em casa. Para isto basta que sejam feitos os cadastros
      de tutor, alunos e as atividades.
    </p>
    <p>
      O tutor deve acessar o modulo PlayTEA Creator e realizar seu cadastro,
      criará os perfis dos alunos e então irá realizar as atividades para cada
      aluno.
    </p>
    <p>
      Os alunos terão acesso através do modulo PlayTEA, seu acesso será por meio
      de usuário e senha. Onde estará disponibilizado as tarefas criadas para
      ele.
    </p>
    <p>
      Para a realização das atividades, é exigido o conhecimento básico de
      informática. Nós equipe PlayTEA, esperamos que você usuário usufrua da
      melhor forma e das possibilidades que a plataforma tem a oferecer.
    </p>
  </>
);

export const Home: FC = (): ReactElement => {
  const history = useHistory();

  const verifyMobile = () => {
    return isMobileOnly ? styles.mobileCard : styles.desktopCard;
  };

  useEffect(() => {
    const firstAccess = localStorage.getItem("firstAccess");

    if (!firstAccess) {
      localStorage.setItem("firstAccess", "true");
      Modal.info({
        title: "Olá, seja bem-vindo",
        content: description,
      });
    }
  }, []);

  useScreenOrientation({ orientation: ScreenOrientation.PORTRAIT });

  return (
    <Layout
      hasHeader={false}
      theme="light"
      style={{ minHeight: isMobileOnly ? 1000 : 1200 }}
    >
      <div style={isMobileOnly ? styles.mobileDiv : styles.deskTopDiv}>
        <Card
          onClick={() =>
            history.push(`${CONTEXTS.PLAYTEA}/entrar`, {
              withCreator: false,
              image: "/playtea-game.jpg",
            })
          }
          hoverable
          style={verifyMobile() as any}
          cover={<img alt="example" src="/playtea-game.jpg" />}
        >
          <Card.Meta
            title="PlayTEA"
            description="Clique aqui para jogar seus jogos"
          />
        </Card>
        <Card
          onClick={() =>
            history.push(`${CONTEXTS.PLAYTEA_CREATOR}/entrar`, {
              withCreator: true,
              image: "/playtea.jpg",
            })
          }
          hoverable
          style={verifyMobile() as any}
          cover={<img alt="example" src="/playtea.jpg" />}
        >
          <Card.Meta
            title="PlayTEA Creator"
            description="Clique aqui para realizar seu cadastro e criar seus jogos"
          />
        </Card>
      </div>
    </Layout>
  );
};
