import React, { FC, ReactElement, useState } from "react";
import { Col, Row, Typography, Modal, message } from "antd";
import { Layout } from "components/layout";
import { Logo } from "components/logo";
import { Grid } from "components/layout/grid";
import { LoginForm } from "components/login-form";
import LoginService from "redux/management/login";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "redux/management/dispatchs";
import { loginAction as loginStudentAction } from "redux/pupil/dispatchs";
import { useIdentifyPlatform } from "routes/utils";
import { useScreenOrientation } from "hooks/useScreenOrientation";
import { ScreenOrientation } from "enums";
import { CONTEXTS } from "utils/enums";
import { saveLocalStudentAction } from "redux/local-users/dispatchs";
import { decrypt } from "utils/crypto";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./styles.less";

export const Login: FC<any> = (): ReactElement => {
  const dispatch = useDispatch();
  const plataform = useIdentifyPlatform();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const {
    managementReducer: { management },
  }: any = useSelector((state) => state);

  const {
    localStudentReducer: { localStudents },
  }: any = useSelector((state) => state);

  const {
    studentReducer: { student },
  }: any = useSelector((state) => state);

  useScreenOrientation({ orientation: ScreenOrientation.PORTRAIT });

  if (plataform.withCreator && management?.codigo)
    return <Redirect to={CONTEXTS.PLAYTEA_CREATOR} />;

  if (!plataform.withCreator && student?.codigo)
    return <Redirect to={CONTEXTS.PLAYTEA} />;

  const loginCreator = async (values: any) => {
    try {
      const response = await LoginService.auth(values);
      if (response.data.result?.codigo) {
        message.success("Login realizado com sucesso");
        dispatch(loginAction(response?.data?.result));
      } else {
        Modal.warning({
          title: "Aviso",
          content: "Usuário e/ou senhas inválidos, tente novamente.",
          onOk: () => {
            ("");
          },
        });
      }
    } catch {
      Modal.error({
        title: "Erro",
        content: "Login não foi realizado com sucesso, tente novamente.",
        onOk: () => {
          ("");
        },
      });
    }
  };

  const loginPortal = async (values: any) => {
    try {
      if (window.navigator.onLine) {
        const response = await LoginService.authPortal(values);
        if (response.data.result?.codigo) {
          message.success("Login realizado com sucesso");
          dispatch(loginStudentAction(response?.data?.result));
          dispatch(
            saveLocalStudentAction({
              ...values,
              codigo: response?.data?.result?.codigo,
              tutor: response?.data?.result?.tutor,
            }),
          );
        } else {
          Modal.warning({
            title: "Aviso",
            content: "Usuário e/ou senhas inválidos, tente novamente.",
            onOk: () => {
              ("");
            },
          });
        }
      } else {
        const localStudentsDecrypted = localStudents.users
          ?.map((user) => JSON.parse(decrypt(user)))
          ?.find(
            (stu) =>
              stu.password === values.password &&
              stu.username === values.username,
          );

        if (localStudentsDecrypted) {
          message.success("Login realizado com sucesso");
          return dispatch(loginStudentAction(localStudentsDecrypted));
        }

        throw new Error("");
      }
    } catch {
      Modal.error({
        title: "Erro",
        content: "Login não foi realizado com sucesso, tente novamente.",
        onOk: () => {
          ("");
        },
      });
    }
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);

    if (plataform.withCreator) {
      await loginCreator(values);
    } else {
      await loginPortal(values);
    }

    setLoading(false);
  };

  return (
    <Layout hasHeader={false} theme="light" style={{ minHeight: 860 }}>
      <div className="login--content">
        <Grid fluid={false} width={700}>
          <Row className="login--panel" justify="center">
            <Col xs={24} sm={12}>
              <ArrowLeftOutlined
                style={{ marginLeft: 10, cursor: "pointer" }}
                onClick={() => history.push("/")}
              />
              <Row justify="center" className="login--panel-left">
                <Logo hasLink={false} height={200} image={plataform.image} />
              </Row>
            </Col>

            <Col xs={24} sm={12}>
              <div className="login--panel-right">
                <Typography.Title
                  level={2}
                  className="common--fontWeight__700"
                  style={{ lineHeight: 1, marginBottom: 24 }}
                >
                  Entrar
                </Typography.Title>

                <LoginForm
                  isSubmitting={loading}
                  plataform={plataform}
                  onFormSubmit={handleSubmit}
                />
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    </Layout>
  );
};
