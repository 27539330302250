import React, { useContext, useState } from "react";
import $ from "jquery";
import Grid from "antd/lib/card/Grid";
import { Row, Col, Input, Button, Modal, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { GameContext } from "../..";
import { If } from "react-extras";
import { v4 as uuidV4 } from "uuid";
import { ModalActions } from "./modal-actions";
import { IntroJS } from "../utils/tutorial";
import { addSound } from "./utils";
import "./styles.less";
import { PAINEL_ACTIONS_SCRIPTS, SCRIPT_NAMES } from "utils/enums";
import { ScriptStep } from "./script-step";

export const PainelAttrsStage = () => {
  const { game } = useContext(GameContext);
  const { gameState } = game;
  const [enableSteps, setEnabledSteps] = useState(false);
  const [visible, setVisible] = useState(false);
  const [script, setScript] = useState("");
  const [scriptSelecteds, setScriptSelecteds] = useState<any[]>([]);

  const assignScriptStage = ({ actionElement, actionStage, action }) => {
    const { currentStage } = gameState;
    const id = uuidV4();
    let errorMessage = "";
    const input = $("<input/>")
      .attr("type", "file")
      .attr("name", "file")
      .attr("id", "add-script");
    switch (Number(action)) {
      case 0:
        break;
      case PAINEL_ACTIONS_SCRIPTS.NEW_AUDIO:
        input.attr("accept", "audio/*");
        input.trigger("click");
        input.on("change focus", async function () {
          const self: any = this;
          if (self.files?.length) {
            const sound = await addSound(self.files[0]);
            currentStage[script][id] = {
              type: "sound",
              name: `Som - ${self.files[0].name}`,
              value: sound,
            };
            game.updateCurrentStage(gameState.currentStage, gameState.stages);
          }
        });
        break;
      case PAINEL_ACTIONS_SCRIPTS.PREVIOUS_STAGE:
        currentStage[script][id] = {
          type: "previous",
          name: "Vai para fase anterior",
          value: true,
        };
        break;
      case PAINEL_ACTIONS_SCRIPTS.NEXT_STAGE:
        currentStage[script][id] = {
          type: "next",
          name: "Vai para próxima fase",
          value: true,
        };
        break;
      case PAINEL_ACTIONS_SCRIPTS.SPECIFY_STAGE:
        if (actionStage) {
          currentStage[script][id] = {
            type: "specify",
            name: `Vai para fase - ${actionStage}`,
            value: actionStage,
          };
        } else errorMessage = "Por favor, selecione uma fase";
        break;
      case PAINEL_ACTIONS_SCRIPTS.END_GAME:
        currentStage[script][id] = {
          type: "end",
          name: "Terminar jogo",
          value: true,
        };
        break;
      case PAINEL_ACTIONS_SCRIPTS.SHOW_SHAPE:
        if (actionElement) {
          currentStage[script][id] = {
            type: "show",
            name: `Mostrar figura - ${actionElement}`,
            value: actionElement,
          };
        } else errorMessage = "Por favor, selecione uma figura";
        break;
      case PAINEL_ACTIONS_SCRIPTS.HIDE_SHAPE:
        if (actionElement) {
          currentStage[script][id] = {
            type: "hide",
            name: `Esconder figura - ${actionElement}`,
            value: actionElement,
          };
        } else errorMessage = "Por favor, selecione uma figura";
        break;
      case PAINEL_ACTIONS_SCRIPTS.AWAITING_TIME:
        if (actionElement) {
          currentStage[script][id] = {
            type: "sleep",
            name: `Aguardar tempo - ${actionElement}`,
            value: actionElement,
          };
        } else errorMessage = "Por favor, insira um valor";
        break;
      default:
        break;
    }

    if (errorMessage) {
      message.info(errorMessage);
    } else {
      game.updateCurrentStage(gameState.currentStage, gameState.stages);
      setVisible(false);
    }
  };

  const createRowsAction = (stage: any, scriptName: string) => {
    const scriptToSearch = scriptSelecteds.find(
      (scriptSelected) => scriptSelected.key === scriptName
    );
    return (
      <Col span={4}>
        <Row>
          <Button
            size="small"
            className="add-script"
            onClick={() => {
              setVisible(true);
              setScript(scriptName);
            }}
          >
            <i className="fa fa-plus" aria-hidden="true" />
          </Button>
        </Row>
        <Row>
          <Button
            size="small"
            className="delete-script"
            disabled={!scriptToSearch}
            onClick={() => {
              console.log(scriptToSearch.key);
              if (scriptToSearch) {
                Reflect.deleteProperty(
                  stage[scriptToSearch.key],
                  scriptToSearch.script
                );
                setScriptSelecteds([]);
                game.updateCurrentStage(stage, gameState.stages);
              }
            }}
          >
            <i className="fa fa-minus" aria-hidden="true" />
          </Button>
        </Row>
        {/* <Row>
          <Button
            size="small"
            disabled={!scriptToSearch}
            className="view-script-detail"
          >
            <i className="fa fa-eye" aria-hidden="true" />
          </Button>
        </Row> */}
      </Col>
    );
  };

  const renderScript = (script: string) => {
    if (gameState.currentStage?.id) {
      const scripts = Object.keys(gameState.currentStage[script]);
      return scripts?.map((scriptAction) => (
        <option value={scriptAction}>
          {gameState.currentStage[script][scriptAction].name}
        </option>
      ));
    }
  };

  const confirmDeleteModal = (id) => {
    Modal.warn({
      title: "Tem certeza que deseja deletar?",
      cancelText: "Voltar",
      okCancel: true,
      onOk: () => {
        game.pauseStages(gameState.stages);
        const stages = gameState.stages.filter((stage: any) => stage.id !== id);
        game.updateStages({ stages });
        const [stage] = stages;
        game.updateCurrentStage(stage);
        gameState.setEvents(stage);
        stage.start(true, gameState);
        message.success("Fase excluida com sucesso");
      },
    });
  };

  return (
    <Grid
      style={{
        width: "100%",
        maxHeight: "600px",
        overflow: "scroll",
        maxWidth: $(window).width(),
      }}
    >
      <IntroJS
        steps={[]}
        stepsEnabled={enableSteps}
        onExit={() => setEnabledSteps(false)}
      />
      <Row
        id="step13"
        align="middle"
        justify="center"
        style={{
          marginTop: "-10px",
          marginBottom: "15px",
          fontWeight: "bold",
        }}
      >
        Configurações da fase atual
        <div className="tutorial-stage" onClick={() => setEnabledSteps(true)}>
          {" "}
          (Ver tutorial){" "}
        </div>
      </Row>
      <Row id="step13" style={{ marginBottom: "10px" }}>
        <Col span={12}> Nome: </Col>
        <Col span={12}>
          <Input
            type="text"
            value={gameState?.currentStage?.name}
            onChange={({ target: { value } }) => {
              gameState.currentStage.setName(value);
              game.draw(gameState);
            }}
          />
        </Col>
      </Row>
      <Row id="step14" style={{ marginBottom: "10px" }}>
        <Col span={12}> Acertos passar de fase? </Col>
        <Col span={12}>
          <Input
            type="number"
            value={gameState?.currentStage?.acertosDoCenario}
            onChange={(event: any) => {
              gameState.currentStage.acertosDoCenario = event.target.value;
              game.draw(gameState);
            }}
          />
        </Col>
      </Row>
      <Row id="step15">
        <ScriptStep
          createRowsAction={createRowsAction}
          gameState={gameState}
          keyScript={SCRIPT_NAMES.QUANDO_INICIAR}
          renderScript={renderScript}
          scriptName="QuandoIniciar?"
          setScriptSelecteds={setScriptSelecteds}
        />
      </Row>
      <Row id="step15" style={{ marginTop: "10px" }}>
        <ScriptStep
          createRowsAction={createRowsAction}
          gameState={gameState}
          keyScript={SCRIPT_NAMES.QUANDO_TERMINAR}
          renderScript={renderScript}
          scriptName="QuandoTerminar?"
          setScriptSelecteds={setScriptSelecteds}
        />
      </Row>
      <Row
        id="step15"
        style={{ marginTop: "10px" }}
        align="middle"
        justify="center"
      >
        <If condition={gameState?.currentStage?.id !== 1}>
          Excluir fase:
          <DeleteOutlined
            style={{ marginLeft: "10px" }}
            className="icon-trash"
            twoToneColor="#eb2f96"
            onClick={() => confirmDeleteModal(gameState.currentStage.id)}
          />
        </If>
      </Row>
      <ModalActions
        noFig={false}
        assignScript={assignScriptStage}
        script={script}
        setVisible={setVisible}
        visible={visible}
      />
    </Grid>
  );
};
