import { Button, Checkbox, Form, Input, InputNumber, Modal, Row } from "antd";
import { Formik } from "formik";
import React, { useCallback, useContext } from "react";
import * as Yup from "yup";
import { GameContext } from "../../..";
import { Shape } from "../../game/classes/Shape";

interface Props {
  onFinish: any;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const validationSchema = Yup.object().shape({
  text: Yup.string().required("Digite uma palavra"),
  size: Yup.number()
    .min(30, "O tamanho precisa ser maior que 10")
    .max(60, "O tamanho tem um limite máximo de 60")
    .required("Digite o tamanho da palavra"),
  breakWord: Yup.boolean(),
  edges: Yup.boolean(),
});

export const LiteracyActivity = ({ onFinish, visible, setVisible }: Props) => {
  const { game } = useContext(GameContext);
  const { gameState } = game;

  const _createCloneAndShape = useCallback(
    ({
      id,
      edges,
      size,
      letter,
      width,
      height,
      xShape,
      yShape,
      xShadow,
      yShadow,
    }) => {
      const measureTextWidth = gameState.currentStage
        .getContext()
        .measureText(letter).width;
      const newWidth = measureTextWidth + width;
      const shadow: any = new Shape({
        x: xShadow,
        y: yShadow,
        width: newWidth,
        height,
        zIndex: 1,
        bordas: edges,
        clickable: false,
        visible: true,
        backGroundColor: "rgba(255, 255, 255, 0.5)",
        velocity: 10,
        id,
        matchId: [],
        text: {
          fill: "green",
          tam: size,
          value: letter,
        },
      });
      const shape = new Shape({
        ...shadow,
        x: xShape,
        y: yShape,
        text: {
          ...shadow.text,
          fill: "black",
        },
        id: id + 1,
        matchId: [id],
        zIndex: 2,
        clickable: true,
      });
      return { shape, shadow };
    },
    [gameState.currentStage],
  );

  const executeActivity = useCallback(
    (data) => {
      const { text, size, breakWord, edges } = data;
      const shadows: any[] = [];
      const shapes: any[] = [];
      const width = 100,
        height = 100;
      let x = gameState.currentStage.width / 2,
        y = gameState.currentStage.height / 2 + width;
      let xShadow = Math.floor(
        Math.random() * (gameState.currentStage.getWidth() - height),
      );
      const yShadow = Math.floor(
        Math.random() * (gameState.currentStage.getHeight() - width),
      );

      const _findMatchIds = () => {
        shapes.forEach((shape) => {
          const shapeIds = shadows.filter(
            (shadowShape) => shadowShape.text.value === shape.text.value,
          );
          shape.matchId = shapeIds.map((shapeIds) => shapeIds.id);
        });
      };

      if (breakWord) {
        x =
          gameState.currentStage.width /
          (text.length > 4 ? text.length - 2 : 2);
        xShadow = x;
        const lastId = Number(gameState.currentStage.getLastIdShape() ?? 0) + 1;
        let i = 0;
        for (const letter of text) {
          const id = lastId + i;
          const { shape, shadow } = _createCloneAndShape({
            id,
            edges,
            size,
            letter,
            width,
            height,
            xShape: x,
            yShape: y,
            xShadow,
            yShadow,
          });
          shadows.push(shadow);
          shapes.push(shape);
          x += width;
          xShadow += width;
          i += 2;
        }

        _findMatchIds();
        gameState.currentStage.addShape(shadows.concat(shapes));
        gameState.currentStage.acertosDoCenario += text.length;
      } else {
        const id = Number(gameState.currentStage.getLastIdShape() ?? 0) + 1;
        const { shape, shadow } = _createCloneAndShape({
          id,
          edges,
          size,
          letter: text,
          xShape: x,
          yShape: y,
          xShadow,
          yShadow,
          width,
          height,
        });
        gameState.currentStage.addShape([shape, shadow]);
        gameState.currentStage.acertosDoCenario += 1;
      }
      onFinish();
    },
    [gameState.currentStage, _createCloneAndShape, onFinish],
  );

  return (
    <Modal visible={visible} footer={null} onCancel={() => setVisible(false)}>
      <Formik
        initialValues={{ text: "", size: 30, breakWord: false, edges: false }}
        validationSchema={validationSchema}
        onSubmit={executeActivity}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-label">Palavra</div>
            <Form.Item
              hasFeedback
              className="common--margin-bottom20"
              validateStatus={touched.text && errors.text ? "error" : undefined}
              help={touched.text && errors.text}
            >
              <Input
                className="form-input"
                name="text"
                value={values.text}
                onChange={handleChange("text")}
                tabIndex={1}
                size="large"
              />
            </Form.Item>

            <div className="form-label">Tamanho</div>

            <Form.Item
              hasFeedback
              validateStatus={touched.size && errors.size ? "error" : undefined}
              help={touched.size && errors.size}
            >
              <InputNumber
                className="form-input"
                onChange={(value) => setFieldValue("size", value)}
                name="size"
                value={values?.size}
                max={60}
                min={10}
                tabIndex={2}
                size="large"
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              validateStatus={
                touched.breakWord && errors.breakWord ? "error" : undefined
              }
              help={touched.breakWord && errors.breakWord}
            >
              <div className="form-label">Fracionado?</div>
              <Checkbox
                className="form-input"
                name="size"
                checked={values.breakWord}
                onChange={(event) =>
                  setFieldValue("breakWord", event.target.checked)
                }
                tabIndex={2}
              />
            </Form.Item>

            <Form.Item
              hasFeedback
              validateStatus={
                touched.edges && errors.edges ? "error" : undefined
              }
              help={touched.edges && errors.edges}
            >
              <div className="form-label">Bordas?</div>
              <Checkbox
                className="form-input"
                name="size"
                onChange={(event) =>
                  setFieldValue("edges", event.target.checked)
                }
                checked={values.edges}
                tabIndex={2}
              />
            </Form.Item>

            <Row justify="center">
              <Button
                type="primary"
                size="large"
                className="common--submit-button"
                style={{ marginRight: 12 }}
                onClick={() => setVisible(false)}
                tabIndex={3}
              >
                Cancelar
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                className="common--submit-button"
                tabIndex={3}
              >
                Criar
              </Button>
            </Row>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
