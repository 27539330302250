import React, { useState } from "react";
import { Row, Col, Form, Input, Button, Modal, message } from "antd";
import { useFormik } from "formik";
import { LoginOutlined } from "@ant-design/icons";
import { RequiredField } from "../../required-field";
import MentoringService from "redux/management";
import * as Yup from "yup";

const initialValues = {
  currentPassword: "",
  newPassword: "",
  cnewPassword: "",
};

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(6, "A senha atual deve conter mais de 6 caracteres")
    .required("Digite sua senha atual"),
  newPassword: Yup.string()
    .min(6, "A senha deve conter mais de 6 caracteres")
    .required("Digite a senha de no mínimo 6 digitos"),
  cnewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "As senhas devem ser iguais")
    .required("Repita a senha"),
});

export const FormResetPassword = ({ showModalChangePassword }) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    onSubmit: () => resetPassword(values),
    validationSchema,
  });

  const { handleChange, values, errors, touched, handleSubmit, resetForm } =
    formik;

  const resetPassword = async (values: any) => {
    try {
      setLoading(true);
      await MentoringService.resetPassword(values);
      message.success({
        content: "Senha alterada com sucesso",
      });
      showModalChangePassword(false)
      resetForm();
    } catch (err) {
      Modal.info({
        content: "Houve um problema ao tentar resetar a senha, tente novamente",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col span={24}>
          <RequiredField>
            <div className="form-label">Senha atual</div>
          </RequiredField>
          <Form.Item
            hasFeedback
            validateStatus={
              touched.currentPassword && errors.currentPassword
                ? "error"
                : undefined
            }
            help={touched.currentPassword && errors.currentPassword}
          >
            <Input
              type="password"
              name="currentPassword"
              value={values.currentPassword}
              onChange={handleChange}
              tabIndex={1}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <RequiredField>
            <div className="form-label">Nova senha</div>
          </RequiredField>
          <Form.Item
            hasFeedback
            validateStatus={
              touched.newPassword && errors.newPassword ? "error" : undefined
            }
            help={touched.newPassword && errors.newPassword}
          >
            <Input
              type="password"
              name="newPassword"
              value={values.newPassword}
              onChange={handleChange}
              tabIndex={1}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <RequiredField>
            <div className="form-label">Repetir nova senha</div>
          </RequiredField>
          <Form.Item
            hasFeedback
            validateStatus={
              touched.cnewPassword && errors.cnewPassword ? "error" : undefined
            }
            help={touched.cnewPassword && errors.cnewPassword}
          >
            <Input
              type="password"
              name="cnewPassword"
              value={values.cnewPassword}
              onChange={handleChange}
              tabIndex={1}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={24} offset={7}>
          <Button
            htmlType="submit"
            loading={loading}
            icon={<LoginOutlined />}
            type="primary"
            size="large"
            style={{ marginTop: "30px" }}
            className="common--submit-button"
            tabIndex={3}
          >
            Resetar senha
          </Button>
        </Col>
      </Row>
    </form>
  );
};
