import React, { FC, ReactElement, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu as AntMenu, Modal } from "antd";
import {
  AppstoreOutlined,
  LogoutOutlined,
  SettingOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { classNames } from "react-extras";
import { logoutAction } from "redux/management/dispatchs";
import { logoutAction as logoutStudentAction } from "redux/pupil/dispatchs";
import { useDispatch } from "react-redux";
import LoginService from "redux/management/login";
import { FormResetPassword } from "../forms/form-reset-password";
import { CONTEXTS } from "utils/enums";

interface Props {
  context: string;
  hideMenu?: boolean;
  mode:
    | "vertical"
    | "vertical-left"
    | "vertical-right"
    | "horizontal"
    | "inline";
  theme: "light" | "dark";
  button?: any;
  isPlayTEA?: boolean;
}

const menuItems = [
  {
    id: 1,
    to: "/alunos",
    icon: <UserOutlined />,
    label: "Alunos",
  },
  {
    id: 2,
    to: "/biblioteca-cenarios",
    icon: <AppstoreOutlined />,
    label: "Cenários",
  },
];

const menuItemsPlayTEA = [];

export const Menu: FC<Props> = ({
  context,
  hideMenu,
  mode,
  theme,
  isPlayTEA,
  button,
}): ReactElement => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentRouter = location.pathname;
  const [modalChangePassword, showModalChangePassword] =
    useState<boolean>(false);
  const logout = async () => {
    try {
      if (window.navigator.onLine) {
        await LoginService.logout(isPlayTEA ? "aluno" : "tutor");
      }
    } finally {
      dispatch(isPlayTEA ? logoutStudentAction() : logoutAction());
    }
  };

  return (
    <>
      <AntMenu mode={mode} theme={theme}>
        {button}
        {!hideMenu &&
          (isPlayTEA ? menuItemsPlayTEA : menuItems).map(
            ({ id, to, icon, label }) => {
              const pathname = getContextName(to, context);
              const activeClassName = classNames({
                "ant-menu-item-active": pathname === currentRouter,
              });

              return (
                <AntMenu.Item
                  key={`${context}-${id}`}
                  className={activeClassName}
                >
                  <Link to={context + to}>
                    {icon}
                    <span className="common--fontWeight__700">{label}</span>
                  </Link>
                </AntMenu.Item>
              );
            },
          )}

        <AntMenu.SubMenu
          key="sub1"
          title={
            <>
              <SettingOutlined />
              <b className="common--fontWeight__700">Perfil</b>
            </>
          }
        >
          {Boolean(context === CONTEXTS.PLAYTEA_CREATOR) && (
            <AntMenu.Item
              key={`${context}-change-password`}
              onClick={() => showModalChangePassword(true)}
            >
              <UnlockOutlined />
              Alterar Senha
            </AntMenu.Item>
          )}

          <AntMenu.Item key={`${context}-logout`} onClick={() => logout()}>
            <LogoutOutlined />
            Sair
          </AntMenu.Item>
        </AntMenu.SubMenu>
      </AntMenu>
      <Modal
        onCancel={() => showModalChangePassword(false)}
        footer={null}
        title="Troca de senha"
        visible={modalChangePassword}
      >
        <FormResetPassword showModalChangePassword={showModalChangePassword} />
      </Modal>
    </>
  );
};

const getContextName = (path: string = "", context: string = "") =>
  context + "/" + path.split("/")[1];
