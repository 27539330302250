import _ from "lodash";
import { getBase64 } from "../../../../../../components/forms/form-register/person-registry";
import { compressImage } from "../../utils/compress-image";

enum SHAPE_TYPES {
  RECTANGLE = "rectangle",
}

const defaultValues = {
  backGroundColor: "#FFFFFF",
  text: { value: "", tam: "40", fill: "#000000" },
  type: SHAPE_TYPES.RECTANGLE,
};

export class Shape {
  public x: number;
  public y: number;
  public width: number;
  public height: number;
  public zIndex: number;
  public clickable: any;
  public visible: boolean;
  public velocity: number;
  public fill?: string;
  public id: number;
  public matchId?: any[] = [];
  public base64Image?: any;
  public text?: any;
  public backGroundColor?: string;
  public opacity: number = 1;
  public primarySound?: any;
  public bordas?: boolean;
  public dificult?: number;
  public quandoAcertar?: any;
  public quandoClicar?: any;
  public quandoErrar?: any;
  public hidden?: boolean;
  public type?: string;
  public posInitialX: number = 0;
  public posInitialY: number = 0;
  public used: boolean = false;
  public velocityX: number = 0;
  public velocityY: number = 0;
  public image64: string;
  public sound: any;
  public states: any[];
  public icon?: string;
  public rotate?: any;
  public angle?: number;
  public cutImage?: any;
  public imageCl: any;
  public imageData?: any;
  public shadow: any;
  public hasShadow = false;
  public animated?: boolean;
  public animatedWidth?: boolean;
  public animatedHeight?: boolean;
  private directionX = 0;
  private directionY = 0;

  constructor({
    x,
    y,
    width,
    height,
    zIndex,
    clickable,
    visible,
    velocity,
    backGroundColor = defaultValues.backGroundColor,
    id,
    matchId,
    base64Image = "",
    text = { ...defaultValues.text },
    opacity = 1,
    primarySound = "",
    bordas = true,
    dificult = 0,
    quandoAcertar = {},
    quandoClicar = {},
    quandoErrar = {},
    hidden = false,
    states = [],
    type = defaultValues.type,
    icon = "",
    rotate = {},
    angle = 0,
    imageCompressed = true,
    shadow = null,
    animated = false,
    animatedHeight = false,
    animatedWidth = false,
  }) {
    this.dificult = dificult;
    this.type = type;
    this.primarySound = primarySound;
    this.opacity = opacity;
    this.animated = animated;
    this.animatedHeight = animatedHeight;
    this.animatedWidth = animatedWidth;
    this.zIndex = zIndex;
    this.x = x;
    this.y = y;
    this.hidden = hidden;
    this.width = width;
    this.height = height;
    this.id = id;
    this.backGroundColor = backGroundColor;
    this.posInitialX = x;
    this.posInitialY = y;
    this.clickable = clickable;
    this.visible = visible;
    this.used = false;
    this.velocity = velocity;
    this.velocityX = 0;
    this.velocityY = 0;
    this.matchId = matchId;
    this.text = text;
    this.image64 = base64Image;
    this.bordas = bordas;
    this.quandoClicar = quandoClicar;
    this.quandoAcertar = quandoAcertar;
    this.quandoErrar = quandoErrar;
    this.states = states;
    this.icon = icon;
    this.rotate = rotate;
    this.angle = angle;
    this.imageCl = new Image();
    this.shadow = shadow;
    this.hasShadow = this.shadow !== null;
    if (!imageCompressed) this.compressImageShape();
  }

  async compressImageShape() {
    if (this.image64) {
      const file = await (await fetch(this.image64)).blob();
      this.image64 = await getBase64(await compressImage(file, 100));
    }
  }

  //resize in mode tablet
  resize(previousHeight, previousWidth, height, width, padx, pady) {
    this.width = (this.width / previousWidth) * width;
    this.height = (this.height / previousHeight) * height;
    this.x = padx + (this.x / previousWidth) * width;
    this.y = pady + (this.y / previousHeight) * height;

    return this;
  }

  setData(data) {
    const dataClone: any[] = [];
    data.forEach(({ info, auxInfo, value }) => {
      dataClone.push(_.cloneDeep({ info, value: this[auxInfo ?? info] }));
      if (value) this[info] = value;
    });
    this.states.push(dataClone);
  }

  /**
   * Verifica o mouse está em cima do objeto
   * @param {Float} mouseX - posição mouse X
   * @param {Float} mouseY - posição mouse y
   */
  contains(mouseX, mouseY) {
    return (
      this.x <= mouseX &&
      this.x + this.width >= mouseX &&
      this.y <= mouseY &&
      this.y + this.height >= mouseY
    );
  }

  fillBorders({ ctx }) {
    ctx.strokeStyle = "#000";
    ctx.lineWidth = 2;
    ctx.setLineDash([0]);
  }

  fillImage({ ctx }) {
    this.imageCl.src = this.image64;
    ctx.drawImage(this.imageCl, this.x, this.y, this.width, this.height);
  }

  fillText({ ctx }) {
    ctx.fillStyle = this.text.fill;
    ctx.font = `${this.text.tam}pt Calibri`;
    ctx.textAlign = "center";
    ctx.fillText(
      this.text.value,
      this.x + this.width / 2,
      this.y + this.height / 2 + Number(this.text.tam / 2),
    );
  }

  painters(ctx) {
    return {
      border: () => this.fillBorders({ ctx }),
      image: () => this.fillImage({ ctx }),
      text: () => this.fillText({ ctx }),
    };
  }

  renderRectangle(ctx, onlyRect = false) {
    ctx.fillRect(this.x, this.y, this.width, this.height);
    if (onlyRect) {
      ctx.restore();
      return;
    }

    if (this.hasShadow && this.shadow >= 0) {
      ctx.filter = `brightness(${this.shadow * 10}%)`;
    }
    if (!this.hidden) {
      if (this.bordas) {
        this.painters(ctx).border();
        ctx.strokeRect(this.x, this.y, this.width, this.height);
      }

      if (this.image64) {
        this.painters(ctx).image();
      }

      if (this.text.value) {
        this.painters(ctx).text();
      }
    } else {
      ctx.strokeStyle = "#000";
      ctx.setLineDash([10]);
      ctx.lineWidth = 3;
      ctx.strokeRect(this.x, this.y, this.width, this.height);
    }

    ctx.restore();
  }

  normalizeSize() {
    if (this.width < 0) {
      this.width = Math.abs(this.width);
      this.x -= this.width;
    }

    if (this.height < 0) {
      this.height = Math.abs(this.height);
      this.y -= this.height;
    }
  }

  drawing(ctx, onlyRect) {
    ctx.save();
    if (onlyRect) {
      if (this.type === SHAPE_TYPES.RECTANGLE) {
        this.renderRectangle(ctx, onlyRect);
      }
      return;
    } else if (this.visible) {
      if (!this.hidden) {
        ctx.globalAlpha = this.opacity;
        if (!this.image64) {
          ctx.fillStyle = this.backGroundColor;
        } else {
          ctx.fillStyle = `rgba(255, 255, 255, 0.01)`;
        }
      } else ctx.fillStyle = "#fff";
      if (this.type === SHAPE_TYPES.RECTANGLE) {
        this.renderRectangle(ctx);
      }
    }
    if (!this.animated) {
      this.updatePosition();
    }
  }

  draw(ctx, onlyRect) {
    this.drawing(ctx, onlyRect);
  }

  animating(ctx, onlyRect, blocksCollision) {
    const halfVelocity = this.velocity === 5 ? 100 : this.velocity / 2;
    for (const blockCollision of blocksCollision) {
      this.directionY =
        this.y + this.height > blockCollision.height ? 1 : this.directionY;
      this.directionX =
        this.x + this.width > blockCollision.width ? 0 : this.directionX;

      if (this.animatedWidth) {
        if (!this.directionX) {
          if (this.x < 0) {
            this.x += halfVelocity;
            this.directionX = 1;
          } else {
            this.x -= halfVelocity;
          }
        } else {
          this.x += halfVelocity;
        }
      }

      if (this.animatedHeight) {
        if (this.directionY) {
          if (this.y > 0) {
            this.y -= halfVelocity;
          } else this.directionY = 0;
        } else {
          this.y += halfVelocity;
        }
      }
      this.drawing(ctx, onlyRect);
    }
  }

  /**
   * Update de velocidade
   */
  updatePosition() {
    if (
      Math.abs(this.x - this.posInitialX) <= this.velocity &&
      Math.abs(this.y - this.posInitialY) <= this.velocity
    ) {
      this.velocityX = 0;
      this.velocityY = 0;
      this.x = this.posInitialX;
      this.y = this.posInitialY;
    } else {
      this.x += this.velocityX;
      this.y += this.velocityY;
    }
  }

  centerX() {
    return this.x + this.halfWidth();
  }

  centerY() {
    return this.y + this.halfHeight();
  }

  halfWidth() {
    return this.width / 2;
  }

  halfHeight() {
    return this.height / 2;
  }
}
