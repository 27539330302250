import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { gameReducer } from "./games/reducer";
import { managementReducer } from "./management/reducer";
import storage from "redux-persist/lib/storage";
import { studentReducer } from "./pupil/reducer";
import { localStudentReducer } from "./local-users/reducer";

const reducers = combineReducers({
  managementReducer,
  gameReducer,
  studentReducer,
  localStudentReducer,
});

const newWindow: any = window;

const composeEnhancers =
  newWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage,
};

const persistedRedux = persistReducer(persistConfig, reducers);

const store = () => {
  const store = createStore(
    persistedRedux,
    composeEnhancers(applyMiddleware()),
  );
  const persistor = persistStore(store);
  return { store, persistor };
};

export default store;
