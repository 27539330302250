import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Modal, Row, Upload } from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import { useState } from "react";
import { GameContext } from "../../..";
import { getBase64 } from "../../../../../../components/forms/form-register/person-registry";
import { Shape } from "../../game/classes/Shape";
import { compressImage } from "../../utils/compress-image";
interface Props {
  onFinish: any;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const getCroppedImg = (image, crop, canvas) => {
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );
  return canvas.toDataURL();
};

export const PuzzleActivity = ({ visible, setVisible }: Props) => {
  const { game } = useContext(GameContext);
  const { gameState } = game;
  const canvas = document.createElement("canvas");
  const [loading, setLoading] = useState<boolean>(false);

  const executeActivity = async (data) => {
    return new Promise((resolve, _) => {
      const horizontalCropImage = new Image();
      const images: any[] = [];
      horizontalCropImage.src = data.image;
      horizontalCropImage.onload = () => {
        const pieceWidth = horizontalCropImage.width / data.difficult;
        for (let i = 0; i < data.difficult; i++) {
          const horizontalCropped = getCroppedImg(
            horizontalCropImage,
            {
              width: pieceWidth,
              height: horizontalCropImage.height,
              x: i * pieceWidth,
              y: 0,
            },
            canvas,
          );
          const verticalCropImage = new Image();
          verticalCropImage.src = horizontalCropped;
          images.push(
            () =>
              new Promise((resolve, _) => {
                verticalCropImage.onload = () => {
                  resolve(verticalCropImage);
                };
              }),
          );
        }

        resolve(images);
      };
    });
  };

  const onSubmit = async (data) => {
    const dimensionsToDigSaw = {
      width: gameState.currentStage.width / 2,
      height: gameState.currentStage.height / 2.5,
    };
    const dimensionsFromShape = {
      width: dimensionsToDigSaw.width / data.difficult,
      height: dimensionsToDigSaw.height / data.difficult,
    };
    const images: any = await executeActivity(data);
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const verticalCropImage = await image();
      const pieceHeight = verticalCropImage.height / data.difficult;
      for (let j = 0; j < data.difficult; j++) {
        let id = Number(gameState.currentStage.getLastIdShape() ?? 0) + 1;
        const verticalCropped = getCroppedImg(
          verticalCropImage,
          {
            width: verticalCropImage.width,
            height: pieceHeight,
            x: verticalCropImage.x,
            y: j * pieceHeight,
          },
          canvas,
        );
        if (verticalCropped.length > 20) {
          const shape = new Shape({
            base64Image: verticalCropped,
            zIndex: 1,
            bordas: true,
            clickable: false,
            visible: true,
            opacity: 0.4,
            backGroundColor: "rgba(255, 255, 255, 0.5)",
            velocity: 10,
            height: dimensionsFromShape.height,
            width: dimensionsFromShape.width,
            x: dimensionsFromShape.width * i,
            y: dimensionsFromShape.height * j,
            id,
            matchId: [],
            imageCompressed: false,
          });

          const _shuffle = () => {
            if (data.shuffle) {
              // todo
              return {
                x: shape.x + dimensionsFromShape.width * data.difficult,
                y: shape.y + dimensionsFromShape.height * data.difficult,
              };
            }

            return {
              x: shape.x + dimensionsFromShape.width / data.difficult,
              y: shape.y + dimensionsFromShape.height * (data.difficult + 0.5),
            };
          };

          const shadow = new Shape({
            base64Image: verticalCropped,
            zIndex: 5,
            bordas: true,
            clickable: true,
            visible: true,
            backGroundColor: "rgba(255, 255, 255, 0.5)",
            velocity: 10,
            height: dimensionsFromShape.height,
            width: dimensionsFromShape.width,
            ..._shuffle(),
            id: id + 1,
            matchId: [shape.id],
            imageCompressed: false,
          });

          gameState.currentStage.addShape([shape, shadow]);
        }
      }
    }
    gameState.currentStage.acertosDoCenario += Math.pow(data.difficult, 2);
    gameState.currentStage.hasPuzzle = true;
  };

  return (
    <Modal visible={visible} footer={null} onCancel={() => setVisible(false)}>
      <Formik
        initialValues={{ difficult: 2, image: "", shuffle: false }}
        validationSchema={""}
        onSubmit={async (data) => {
          try {
            setLoading(true);
            await onSubmit(data);
          } finally {
            setLoading(false);
          }
        }}
      >
        {({ errors, handleSubmit, touched, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <div className="form-label">Imagem</div>
              <Upload
                name={"image"}
                listType={"picture"}
                accept={"image/*"}
                customRequest={() => true}
                fileList={
                  (values.image && [
                    {
                      status: "done",
                      name: "Foto",
                      uid: "1",
                      size: 0,
                      type: "",
                      thumbUrl: values.image,
                    },
                  ]) ||
                  []
                }
                onChange={async (info) => {
                  const file = info.fileList[info.fileList.length - 1];
                  if (file) {
                    file.status = "done";
                    const imageComp = await compressImage(file.originFileObj);
                    const imageBase64 = await getBase64(imageComp);
                    return setFieldValue("image", imageBase64);
                  }
                  setFieldValue("foto", null);
                }}
              >
                <Button>
                  <UploadOutlined /> Buscar
                </Button>
              </Upload>
            </div>

            <Form.Item
              hasFeedback
              style={{ marginBottom: 10, marginTop: 5 }}
              validateStatus={
                touched.difficult && errors.difficult ? "error" : undefined
              }
              help={touched.difficult && errors.difficult}
            >
              <div className="form-label">Dificuldade</div>
              <InputNumber
                className="form-input"
                onChange={(value) => setFieldValue("difficult", value)}
                name="difficult"
                value={values?.difficult}
                max={10}
                min={1}
                tabIndex={2}
                size="large"
              />
            </Form.Item>

            <Row justify="center">
              <Button
                type="primary"
                size="large"
                className="common--submit-button"
                style={{ marginRight: 12 }}
                onClick={() => setVisible(false)}
                tabIndex={3}
              >
                Cancelar
              </Button>
              <Button
                disabled={!values.image}
                loading={loading}
                htmlType="submit"
                type="primary"
                size="large"
                className="common--submit-button"
                tabIndex={3}
              >
                Criar
              </Button>
            </Row>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
