import { compressImage } from "../utils/compress-image";

export const addSound = async (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.addEventListener("load", function () {
        return resolve(this.result);
      });

      const newBlob = new Blob([file.slice(0, 500000)], { type: "audio/wav" });
      reader.readAsDataURL(newBlob);
    } catch (error) {
      return reject(error);
    }
  });
};

export const showFigure = async (input) => {
  const compress = await compressImage(input.files[0]);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event: any) =>
      resolve(event.target.result),
    );
    reader.addEventListener("error", (event) => reject(event));
    reader.readAsDataURL(compress);
  });
};
