import { message, Modal, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import GamesService from "../../../redux/games/games";
import { exportGame } from "pages/playtea-creator/create-game/navigator";
import { v4 } from "uuid";

interface Props {
  visible: boolean;
  gameState?: any;
  gameProps?: any;
  setVisible?: (value: any) => void;
  studentCode: string;
  setLoadingExport?: (value: boolean) => void;
}
export const PasswordExportModal = ({
  visible,
  gameState,
  gameProps,
  setVisible,
  studentCode,
  setLoadingExport,
}: Props) => {
  const [password, setPassword] = useState("");

  const fetchGame = async (gameProps, setLoader) => {
    try {
      setLoader(gameProps.codigo);
      const response = await GamesService.get(studentCode, gameProps.codigo);
      return response.data.result;
    } catch {
      message.warn(
        "Nao foi possivel buscar as informacoes do jogo, tente novamente"
      );
    } finally {
      setLoader("");
    }
  };

  useEffect(() => {
    if (visible) {
      setPassword(v4().replace(/-/g, "").slice(0, 6));
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title="Código de acesso ao jogo"
      onOk={async () => {
        let gameData = gameState;
        if (!gameData) {
          const data = await fetchGame(gameProps, setLoadingExport);

          gameData = {
            ...JSON.parse(data.game),
            name: data.nome,
            foto: data.foto,
            game: null,
          };
        }

        exportGame(gameData, password);
        setVisible?.(false);
      }}
      okText="Compartilhar"
      cancelText="Cancelar"
      onCancel={() => setVisible?.(false)}
    >
      <Row justify="center" align="middle">
        <Typography.Title level={5}>
          Use a senha abaixo para compartilhar o seu jogo:
        </Typography.Title>
        <Typography.Title>{password}</Typography.Title>
      </Row>
    </Modal>
  );
};
