import { Input, Modal, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
export const PasswordImportModal = ({
  visible,
  callBack,
  setVisible,
  loading,
}) => {
  const [password, setPassword] = useState("");


  useEffect(() => {
    if (!visible) {
      setPassword('')
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      title="Código de acesso ao jogo"
      okButtonProps={{
        disabled: !password.length,
        loading,
      }}
      onOk={async () => callBack(password)}
      okText="Importar"
      cancelText="Cancelar"
      onCancel={() => setVisible(false)}
    >
      <Row justify="center" align="middle">
        <Typography.Title level={5}>
          Digite a senha abaixo para importar o jogo:
        </Typography.Title>
        <Input
          type="text"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </Row>
    </Modal>
  );
};
