import { useMemo } from "react";
import { useHistory } from "react-router-dom";

enum SYSTEM {
  PLAYTEA_CREATOR = "playtea-creator",
  PLAYTEA_PLATFORM = "playtea",
}

export const useIdentifyPlatform = () => {
  const history = useHistory();
  const creator = useMemo(
    () => history?.location?.pathname.includes(SYSTEM.PLAYTEA_CREATOR),
    [history],
  );

  const data = useMemo(
    () =>
      creator
        ? {
            link: SYSTEM.PLAYTEA_CREATOR,
            withCreator: true,
            image: "/playtea.jpg",
          }
        : {
            link: SYSTEM.PLAYTEA_PLATFORM,
            withCreator: false,
            image: "/playtea-game.jpg",
          },
    [creator],
  );

  return data;
};
