import React, { useState, useContext } from "react";
import { Modal, Select, Row, message, Input } from "antd";
import { If } from "react-extras";
import { GameContext } from "../..";
import $ from "jquery";
import { PAINEL_ACTIONS_SCRIPTS } from "utils/enums";
interface Props {
  script: string;
  visible: boolean;
  setVisible: any;
  assignScript: any;
  noFig?: boolean;
}

export const ModalActions = ({
  script,
  visible,
  setVisible,
  assignScript,
  noFig = true,
}: Props) => {
  const { game } = useContext(GameContext);
  const [action, setAction] = useState(-1);
  const [actionStage, setActionStage] = useState("");
  const [actionElement, setActionElement] = useState<any>();
  const { gameState } = game;
  return (
    <Modal
      okText="Adicionar"
      cancelText="Voltar"
      title={`Ações - ${script}`}
      visible={visible}
      onOk={() => {
        if (action === -1) {
          message.info("Por favor, selecione uma ação.");
        } else {
          assignScript({ actionStage, actionElement, action });
        }
      }}
      onCancel={() => {
        setVisible(false);
        $("body").find("#add-script")?.remove();
      }}
    >
      <div>
        <Row align="middle" style={{ display: "grid" }}>
          <h5> Ações </h5>
          <Select onChange={(value: any) => setAction(value)}>
            <Select.Option value="-1"> </Select.Option>
            {noFig && (
              <Select.Option value={PAINEL_ACTIONS_SCRIPTS.SHOW_SHAPE}>
                mostrar figura
              </Select.Option>
            )}
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.NEW_AUDIO}>
              tocar som
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.PREVIOUS_STAGE}>
              vai para fase anterior
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.NEXT_STAGE}>
              vai para próxima fase
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.SPECIFY_STAGE}>
              vai para fase desejada
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.END_GAME}>
              terminar jogo
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.SHOW_SHAPE}>
              mostrar elemento
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.HIDE_SHAPE}>
              esconder elemento
            </Select.Option>
            <Select.Option value={PAINEL_ACTIONS_SCRIPTS.AWAITING_TIME}>
              aguardar tempo
            </Select.Option>
          </Select>
        </Row>
        <If condition={action === PAINEL_ACTIONS_SCRIPTS.SPECIFY_STAGE}>
          <Row style={{ display: "grid", marginTop: 12 }}>
            <h5> Fases </h5>
            <Select onChange={(value: any) => setActionStage(value)}>
              <Select.Option value="-1"> </Select.Option>
              {gameState?.stages?.map((stage) => (
                <Select.Option value={stage.id}>
                  Fase - {stage.name}
                </Select.Option>
              ))}
            </Select>
          </Row>
        </If>
        <If
          condition={
            action === PAINEL_ACTIONS_SCRIPTS.SHOW_SHAPE ||
            action === PAINEL_ACTIONS_SCRIPTS.HIDE_SHAPE
          }
        >
          <Row style={{ display: "grid", marginTop: 12 }}>
            <h5> Elementos </h5>
            <Select onChange={(value: any) => setActionElement(value)}>
              <Select.Option value="-1"> </Select.Option>
              {gameState?.currentStage?.shapes
                ?.filter((shape) => shape.id !== 1000000)
                ?.sort((a, b) => a.id - b.id)
                .map((shape) => (
                  <Select.Option value={shape.id}>
                    Número da figura - {shape.id}
                  </Select.Option>
                ))}
            </Select>
          </Row>
        </If>
        <If condition={action === PAINEL_ACTIONS_SCRIPTS.AWAITING_TIME}>
          <Row style={{ display: "grid", marginTop: 12 }}>
            <h5> Tempo (em segundos) </h5>
            <Input
              type="number"
              onChange={(event: any) =>
                setActionElement(Number(event.target.value))
              }
            />
          </Row>
        </If>
      </div>
    </Modal>
  );
};
