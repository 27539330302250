import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  Button,
  Breadcrumb,
  Row,
  Tooltip,
  Col,
  Card,
  message,
  Modal,
  Spin,
} from "antd";
import { Layout } from "../../../components/layout";
import { Link, useHistory } from "react-router-dom";
import { useScreenOrientation } from "../../../hooks/useScreenOrientation";
import { ScreenOrientation } from "../../../enums";
import { Choose } from "react-extras";
import {
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { LoaderBlock } from "../../../components/loader-block";
import GameService from "../../../redux/games/games";
import { useSelector } from "react-redux";
import { bufferToBase64 } from "../../../utils/base64";
import { CONTEXTS } from "../../../utils/enums";
export const CreateScenes: FC = (): ReactElement => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [requested, setRequested] = useState<any>({ started: false });
  const [scenes, setScenes] = useState<any[]>([]);
  const [loadingCard, setLoadingCard] = useState("");
  const { managementReducer }: any = useSelector((state) => state);
  useScreenOrientation({ orientation: ScreenOrientation.PORTRAIT });

  const { management } = managementReducer;

  useEffect(() => {
    if (!requested?.started) getScenes(management?.codigo);
  }, [management, requested, scenes]);

  const getScenes = async (tutorCode: any) => {
    try {
      setLoading(true);
      const {
        data: { result },
      } = await GameService.scenes(tutorCode, 0, "total");
      setScenes(result);
      setRequested({ success: true, started: true });
    } catch {
      setRequested({ success: false, started: true });
    } finally {
      setLoading(false);
    }
  };

  const deleteScene = (scene: any) => {
    Modal.info({
      title: "Deletar",
      content: `Tem certeza que deseja deletar o cenário ${scene.nome}?`,
      cancelText: "Voltar",
      okText: "Excluir",
      okButtonProps: {
        className: "button-delete",
      },
      okCancel: true,
      onOk: async () => {
        try {
          await GameService.removeScene(scene.codigo);
          getScenes(management?.codigo);
          message.success(`Cenário ${scene.nome} deletado com sucesso.`);
        } catch {
          message.error(
            `Não foi possivel excluir o jogo ${scene.nome}, tente novamente`
          );
        }
      },
    });
  };

  const visibilityScene = async (scene: any) => {
    const currentGame = scenes.find((g: any) => g.codigo === scene.codigo);
    currentGame.disable = currentGame.disable === 0 ? 1 : 0;
    try {
      await GameService.updateEyeVisibility(
        scene.codigo,
        currentGame.disable,
        true
      );
      setScenes([...scenes]);
      message.success(
        `Cenário ${currentGame.disable === 0 ? "habilitado" : "desabilitado"}`
      );
    } catch {
      message.error("Cenário não foi habilitado/desabilitado, tente novamente");
    }
  };

  const newGameButton = (
    <Button
      style={{
        fontWeight: 700,
        fontSize: 16,
        height: "47px",
        marginRight: "20px",
      }}
      disabled={false}
      type="primary"
      size="large"
      className="common--submit-button"
      onClick={() =>
        history.push(`${CONTEXTS.PLAYTEA_CREATOR}/cenarios/create`, {
          game: {},
          scene: true,
        })
      }
    >
      Novo cenário
    </Button>
  );

  const fetchScene = (scene) => {
    setLoadingCard(scene.codigo);
    GameService.getScene(scene.codigo)
      .then((response) => {
        const [scene] = response.data.result;
        if (scene.game) {
          history.push(`${CONTEXTS.PLAYTEA_CREATOR}/cenarios/create`, {
            game: scene,
            scene: true,
          });
        } else {
          message.warn(
            "Nao foi possivel buscar as informacoes do cenario, tente novamente"
          );
        }
      })
      .catch(() => {
        message.warn(
          "Nao foi possivel buscar as informacoes do cenario, tente novamente"
        );
      })
      .finally(() => {
        setLoadingCard("");
      });
  };

  return (
    <Layout hasHeader theme="light">
      <Breadcrumb style={{ display: "flex", justifyContent: "center" }}>
        <Breadcrumb.Item>
          <Link to={CONTEXTS.PLAYTEA_CREATOR}>Home</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>Cenários</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={24} justify="center" style={{ marginBottom: "40px" }}>
        {newGameButton}
      </Row>
      <Row
        gutter={24}
        justify="center"
        style={{ display: "flex", marginRight: "0px", marginLeft: "0px" }}
      >
        <Choose>
          <Choose.When condition={requested?.started && !requested?.success}>
            <div>Você ainda não possui nenhum cenário criado.</div>
          </Choose.When>
          <Choose.When condition={loading}>
            <LoaderBlock text="Buscando Cenários" spinning={loading} />
          </Choose.When>
          <Choose.When condition={scenes.length > 0}>
            {scenes.map((scene: any) => {
              const foto = scene?.foto?.data
                ? atob(bufferToBase64(scene.foto.data)).replace("./images", "")
                : scene.foto;
              scene.foto = foto;
              return (
                <Col key={scene.codigo} style={{ minHeight: 500 }}>
                  <Card
                    key={scene.codigo}
                    hoverable
                    style={{
                      width: 300,
                      minHeight: 400,
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                    cover={
                      <Spin spinning={loadingCard === scene.codigo}>
                        <img
                          style={{
                            maxHeight: 327,
                            minHeight: 327,
                            maxWidth: "100%",
                          }}
                          alt="example"
                          src={scene.foto}
                        />
                      </Spin>
                    }
                    onClick={() => fetchScene(scene)}
                  >
                    <Card.Meta
                      title={scene.nome}
                      description={scene.descricao}
                    />
                  </Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "end",
                      marginTop: "-15px",
                    }}
                  >
                    <Tooltip title={`Deletar jogo: ${scene.nome}`}>
                      <DeleteOutlined
                        className="icon-trash"
                        onClick={async () => {
                          deleteScene(scene);
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      title={`Habilitar/desabilitar cenário: ${scene.nome}`}
                    >
                      {scene.disable === 0 ? (
                        <EyeOutlined
                          className="icon-edit"
                          onClick={async () => visibilityScene(scene)}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          className="icon-edit"
                          onClick={async () => visibilityScene(scene)}
                        />
                      )}
                    </Tooltip>
                  </div>
                </Col>
              );
            })}
          </Choose.When>
          <Choose.Otherwise>
            <div>Você ainda não possui nenhum cenário criado.</div>
          </Choose.Otherwise>
        </Choose>
      </Row>
    </Layout>
  );
};
