import React from "react";
import { Popover, Row } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

export const Shortcuts = () => (
  <Popover
    content={
      <>
        <Row>
          <b className="atalhos-info">Dois clicks:</b>
          abre painel de configurações gerais
        </Row>
        <Row>
          <b className="atalhos-info">CTRL + C com elemento selecionado:</b>
          copia o elemento
        </Row>
        <Row>
          <b className="atalhos-info">CTRL + V com elemento selecionada:</b>
          cria um elemento a partir de um copiado
        </Row>
        <Row>
          <b className="atalhos-info">CTRL + Z com elemento selecionado:</b>
          desfaz uma modificação da figura
        </Row>
        <Row>
          <b className="atalhos-info">
            SHIFT + Setas (com elemento selecionado):
          </b>
          movimenta o elemento com velocidade baixa
        </Row>
        <Row>
          <b className="atalhos-info">Click + movimentação para direita:</b>
          abre painel de configurações do jogo
        </Row>
        <Row>
          <b className="atalhos-info">Click + movimentação para esquerda:</b>
          abre painel de configurações da fase
        </Row>
        <Row>
          <b className="atalhos-info">Setas (com elemento selecionado):</b>
          movimenta o elemento
        </Row>
        <Row>
          <b className="atalhos-info">Delete (com elemento selecionado):</b>
          exclui o elemento
        </Row>
      </>
    }
  >
    <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 22 }} />
  </Popover>
);
