import React from "react";
import { Col, Row } from "antd";
import { SCRIPT_NAMES } from "utils/enums";

interface ScriptStepProps {
  scriptName: string;
  setScriptSelecteds: (data: any[]) => void;
  renderScript: any;
  gameState: any;
  keyScript: SCRIPT_NAMES;
  createRowsAction: any;
}

export const ScriptStep = ({
  keyScript,
  gameState,
  scriptName,
  renderScript,
  createRowsAction,
  setScriptSelecteds,
}: ScriptStepProps) => {
  return (
    <>
      <Col span={8} style={{ display: "flex" }}>
        <Row align="middle" justify="center">
          {scriptName}
        </Row>
      </Col>
      <Col span={12}>
        <select
          multiple
          style={{ width: "100%", height: "100%" }}
          onClick={(event: any) => {
            const value = event.target.value;
            if (value) {
              setScriptSelecteds([
                {
                  key: keyScript,
                  script: value,
                },
              ]);
            }
          }}
        >
          {renderScript(keyScript)}
        </select>
      </Col>
      {createRowsAction(gameState.currentStage, keyScript)}
    </>
  );
};
