import React, { useContext, useState } from "react";
import { Card, Col, message, Modal, Radio, Row, Typography } from "antd";
import { Choose } from "react-extras";
import { LoaderBlock } from "../../../components/loader-block";
import GameService from "../../../redux/games/games";
import { bufferToBase64 } from "../../../utils/base64";
import { isMobile } from "react-device-detect";
import { Stage } from "./canvas/game/classes/Stage";
import { Shape } from "./canvas/game/classes/Shape";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { GameContext } from ".";

const createNewStage = async ({
  scene,
  gameState,
  gameContext,
  savedSameStage,
  setModalScenes,
}) => {
  const {
    data: { result },
  } = await GameService.getScene(scene.codigo);

  const [sceneGame] = result;

  const {
    stages: [gameSceneToAdd],
  } = JSON.parse(sceneGame.game ?? "{}");

  if (gameSceneToAdd) {
    if (savedSameStage) {
      gameSceneToAdd.shapes?.forEach((shape: any) => {
        let newShape = new Shape({
          ...shape,
          base64Image: shape.image64,
          image64: "",
        });
        gameState.currentStage.addShape([newShape]);
      });
      gameState.currentStage.acertosDoCenario += Number(
        gameSceneToAdd.acertosDoCenario ?? 0
      );

      gameState.currentStage.quandoIniciar = {
        ...gameState.currentStage.quandoIniciar,
        ...gameSceneToAdd.quandoIniciar,
      };

      gameState.currentStage.ids = gameState.currentStage.ids.concat(
        gameSceneToAdd.ids
      );

      gameState.currentStage.quandoTerminar = {
        ...gameState.currentStage.quandoTerminar,
        ...gameSceneToAdd.quandoTerminar,
      };
    } else {
      gameContext.pauseStages(gameState.stages);
      const id: number =
        gameState.stages.sort((a, b) => b.id - a.id)[0]?.id || 0;
      const stage = new Stage(
        document.querySelector("canvas"),
        id + 1,
        gameSceneToAdd.ids,
        [],
        gameSceneToAdd.quandoIniciar,
        gameSceneToAdd.quandoTerminar,
        Number(gameSceneToAdd.acertosDoCenario)
      );
      gameSceneToAdd.shapes?.forEach((shape: any) => {
        let newShape = new Shape({
          ...shape,
          base64Image: shape.image64,
          image64: "",
        });
        stage.addShape([newShape]);
      });
      stage.setName(scene?.nome);
      gameContext.updateStages({ stages: [...gameState.stages, stage] });
      gameContext.updateCurrentStage(stage);
      gameState.setEvents(stage);
    }
    message.success("Fase adicionada com sucesso");
    setModalScenes(false);
  }
};

export const ScenesAvailable = ({ modalScenes, setModalScenes }: any) => {
  const [scenes, setScenes] = useState<any[]>([]);
  const { game: gameContext } = useContext(GameContext);
  const { gameState } = gameContext;
  const [loading, setLoading] = useState<boolean>(false);
  const [requested, setRequested] = useState<any>({ started: false });
  const { managementReducer }: any = useSelector((state) => state);
  const { management } = managementReducer;
  const [savedSameStage, setSavedSameStage] = useState<number>(0);

  const getScenes = async (tutorCode: any) => {
    setLoading(true);
    setRequested({ started: true });
    try {
      const {
        data: { result },
      } = await GameService.scenes(tutorCode, 0);
      setScenes(result);
      setRequested({ started: true, success: true });
    } catch {
      setRequested({ started: true, success: false });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modalScenes && !requested.started) {
      getScenes(management?.codigo);
    }
  }, [modalScenes, management, requested]);

  useEffect(() => {
    if (!modalScenes) setRequested({ started: false });
  }, [modalScenes]);

  return (
    <>
      <Modal
        title="Cenários"
        footer={null}
        visible={modalScenes}
        onCancel={() => {
          setModalScenes(false);
          setScenes([]);
        }}
      >
        <Choose>
          <Choose.When
            condition={requested.started && !requested.success && !loading}
          >
            <span> Não foi possivel buscar os cenários, tente novamente</span>
          </Choose.When>
          <Choose.When condition={loading}>
            <LoaderBlock text="Carregando cenários" spinning={loading} />
          </Choose.When>
          <Choose.When condition={Boolean(scenes.length)}>
            <Row justify="center" align="middle">
              {scenes.map((scene: any) => {
                const foto = scene?.foto?.data
                  ? atob(bufferToBase64(scene.foto.data)).replace(
                      "./images",
                      ""
                    )
                  : scene.foto;
                scene.foto = foto;
                return (
                  <Col
                    span={isMobile ? 24 : 12}
                    key={scene.codigo}
                    style={{ padding: 10 }}
                  >
                    <Card
                      key={scene.codigo}
                      hoverable
                      style={{
                        maxHeight: 200,
                        textAlign: "center",
                        marginBottom: "20px",
                      }}
                      cover={
                        <img
                          style={{ maxHeight: 130, minHeight: 130 }}
                          alt="example"
                          src={scene.foto}
                        />
                      }
                      onClick={() =>
                        createNewStage({
                          scene,
                          gameState,
                          gameContext,
                          savedSameStage,
                          setModalScenes,
                        })
                      }
                    >
                      <Card.Meta
                        title={scene.nome}
                        description={scene.descricao}
                      />
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <Row align="middle" justify="center">
              <Radio.Group
                onChange={(value) => {
                  setSavedSameStage(value.target.value);
                }}
                value={savedSameStage}
              >
                <Radio value={0}>Criar um novo cenário</Radio>
                <Radio value={1}>Adicionar fase no cenário atual</Radio>
              </Radio.Group>
            </Row>
          </Choose.When>
          <Choose.Otherwise>
            <Row justify="center">
              <Typography.Title level={4}>
                Nenhum cenário encontrado
              </Typography.Title>
            </Row>
          </Choose.Otherwise>
        </Choose>
      </Modal>
    </>
  );
};
